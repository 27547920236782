//
// Search.
//
import Constants from '../config/Constants'

class Util {
    //
    // Compute the categories from A which are not found in B (difference).
    // https://medium.com/@alvaro.saburido/set-theory-for-arrays-in-es6-eb2f20a61848
    //
    static difference = (arrayA, arrayB) => {
        if (arrayA && arrayB) {
            return arrayA.filter(element => !arrayB.includes(element))
        }
        return arrayA || []
    }
    //
    // Escape special characters in a string used as regular expression.
    // https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
    //
    static escapeRegExp = (s) => {
        return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    //
    // Get the label for the given language from a multi-language string.
    //
    // https://stackoverflow.com/questions/12001953/javascript-and-regex-split-string-and-keep-the-separator
    //
    static getLabel = (label, language) => {
        //
        // If the labels are stored in an object, return the appropriate one.
        //
        if (typeof(label) === 'object') {
            return label[language || Constants.defaultParameterValue[Constants.parameter.language]] || `Missing label for language '${language}' in label ${JSON.stringify(label)}`
        }
        //
        // Split the label string so that element 0 contains the default label, e.g.
        // 'Auto[en]Car[fr]Voiture'
        // The following pairs contain the language and corresponsing label, e.g.,
        // 1: en, 2: English label, 3: fr, 4: French label
        //
        const labels = label ? label.split(/\[([a-z]{2})\]/) : [ '' ]
        if (language) for (var i = 1; i < labels.length - 1; i += 2) {
            if (labels[i] === language) {
                return labels[i + 1]
            }
        }
        return labels[0]
    }
    //
    // Get the name of the page parameter depending on the active source.
    //
    static getPageParameter = (source) => {
        return `${Constants.parameter.page}${source || ''}`
    }
    //
    // Compute the categories from A which are also found in B (intersection).
    // https://medium.com/@alvaro.saburido/set-theory-for-arrays-in-es6-eb2f20a61848
    //
    static intersection = (arrayA, arrayB) => {
        return arrayA.filter(element => arrayB.includes(element))
    }
    //
    // Check if the given value is an array with at least one element or is another type
    // of object having a value.
    //
    static isValue = (value) => {
        if (Array.isArray(value)) {
            return (value.length > 0)
        }
        return value
    }
    //
    // Send a POST request with the given JSON payload and wait for a response to return.
    //
    static submitPostRequest = async(url, payload) => {
        return this.submitRequest(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
    }
    //
    // Send a request (including options) and wait for a response to return.
    // https://stackoverflow.com/questions/50330795/fetch-api-error-handling
    //
    static submitRequest = async(url, payload) => {
        //
        // Add a timeout condition and start a search.
        // https://stackoverflow.com/questions/46946380/fetch-api-request-timeout
        //
        const controller = new AbortController()
        const timeoutId = setTimeout(() => controller.abort(), 60000)
        return await fetch(url, {
            ...payload,
            signal: controller.signal
        }).then((response) => {
            clearTimeout(timeoutId)
            if (!response.ok) {
                return Promise.reject(response)
            }
            //
            // Return the response parsed as JSON (an empty object if the response
            // was empty, i.e., HTTP status code 204) or text based on content-type.
            //
            if (response.status === 204) {
                return {}
            }
            const contentType = response.headers.get('content-type') || ''
            return contentType.startsWith('text/') ? response.text() : response.json()
        }).then((data) => {
            return data
        }).catch((error) => {
            if (typeof(error.json) === 'function') {
                error.json().then((jsonError) => {
                    console.log(`JSON error from API: ${JSON.stringify(jsonError)}`)
                }).catch((genericError) => {
                    console.log(`Generic error from API (${error.statusText}): ${genericError}`)
                })
            }
            else if (error.name === 'AbortError') {
                return Promise.reject(`The request has timed out. Please try again later.`)
            } else {
                console.log(`Fetch method error: ${error}`)
            }
            return Promise.reject(error)
        })
    }
    //
    // Compute the categories from A which are not found in B and vice-versa (symmetrical difference).
    // https://medium.com/@alvaro.saburido/set-theory-for-arrays-in-es6-eb2f20a61848
    //
    static symmetricalDifference = (arrayA, arrayB) => {
        return this.difference(arrayA, arrayB).concat(this.difference(arrayB, arrayA))
    }
}

export default Util