//
// React.
//
import React from 'react';
//
// Material UI.
//
import { Typography } from '@mui/material';
//
// CSS.
//
import './Footer.css';
import './NewsLetter/newsletter.css';

const Footer = (props) => {
  //
  // Redirect to the main page
  //
  const handleLogoClick = () => {
    window.location.href = 'https://www.st.com/';
  };
  //
  // Determine the footer content to render based on API result or default JSON
  //
  const footerContent = props.footerJson || {};
  const footerContentSTLogo= footerContent && footerContent["st-logo"];
  const footerContentDataBasic = footerContent && footerContent["footer-basic-content"];
  const footerContentDataNewsLetter = footerContent && footerContent["footer-newsletter-content"];
  const footerContentDataFollowus = footerContent && footerContent["footer-followus-content"];
  const footerContentDataTerms= footerContent && footerContent["footer-terms-content"];
  const footerNewsletterHTML = props.footerNewsletterHTML || {};

  return (
    <div className="footer">
      {footerContentDataBasic && footerContentDataNewsLetter && footerContentDataFollowus && footerContentDataTerms ? (
        <>
          <div className="footer-logo">
            <img src={footerContentSTLogo} alt="Logo" onClick={handleLogoClick} />
          </div>
          <div className="footer-content">
          <div className="footer-column about">
            {/* Render the basic content */}
            <Typography variant="h6Footer">
              {footerContentDataBasic.items[0].title}
            </Typography>
            <ul>
              {footerContentDataBasic.items[0].children.map((child, index) => (
                <li key={index}>
                  <Typography
                    variant="aFooter"
                    component="a"
                    href={child.url}
                  >
                    {child.title}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-column connect">
            {/* Render the connect with us content */}
            <Typography variant="h6Footer">
              {footerContentDataBasic.items[1].title}
            </Typography>
            <ul>
              {footerContentDataBasic.items[1].children.map((item, index) => (
                <li key={index}>
                  <Typography
                    variant="aFooter"
                    component="a"
                    href={item.url}
                  >
                    {item.title}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-column browse">
            {/* Render the browse content */}
            <Typography variant="h6Footer">
              {footerContentDataBasic.items[2].title}
            </Typography>
            <ul>
              {footerContentDataBasic.items[2].children.map((item, index) => (
                <li key={index}>
                  <Typography
                    variant="aFooter"
                    component="a"
                    href={item.url}
                  >
                    {item.title}
                  </Typography>
                </li>
              ))}
            </ul>
              <div className="DependentPrivacy">
              {/* Render the privacy content */}
              <Typography variant="h6Footer">
                {footerContentDataBasic.items[3].title}
              </Typography>
              <ul>
                {footerContentDataBasic.items[3].children.map((item, index) => (
                  <li key={index}>
                    <Typography
                      variant="aFooter"
                      component="a"
                      href={item.url}
                    >
                      {item.title}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="footer-column privacy">
            {/* Render the privacy content */}
            <Typography variant="h6Footer">
              {footerContentDataBasic.items[3].title}
            </Typography>
            <ul>
              {footerContentDataBasic.items[3].children.map((item, index) => (
                <li key={index}>
                  <Typography
                    variant="aFooter"
                    component="a"
                    href={item.url}
                  >
                    {item.title}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-column news">
            {
              Object.keys(footerNewsletterHTML).length > 0 ? (
                <div className="footer-newsletter" dangerouslySetInnerHTML={{ __html: footerNewsletterHTML }} />
              ) : (
                <div className="footer-newsletter" style={{ marginBottom: '65%' }}>
                  <Typography variant="h6Footer">
                    {footerContentDataNewsLetter.title}
                  </Typography>
                  <form>
                    <input
                      type={footerContentDataNewsLetter.form.inputfield.type}
                      placeholder={footerContentDataNewsLetter.form.inputfield.placeholder}
                    />
                    <button type="submit">{footerContentDataNewsLetter.form.submit.title}</button>
                  </form>
                </div>
              )
            }
            <div className="footer-social">
              {/* Render the social media icons */}
              <Typography variant="h6Footer">
                {footerContentDataFollowus.title}
              </Typography>
              <ul>
                {footerContentDataFollowus.items.map((item, index) => (
                  <li key={index}>
                    {item.icon && item.url && (
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <div dangerouslySetInnerHTML={{ __html: item.icon }} />
                      </a>
                    )}
                    {item['we-chat-logo-text'] && (
                      <div className="st-wechat-component-grid">
                        <div className="st-wechat-component-item">
                          <div className="st-wechat-component-qrcode">
                            <img src={item['we-chat-qr-code']} alt="WeChat QR Code" />
                          </div>
                        </div>
                        <div className="st-wechat-component-item">
                          <div className="st-wechat-logo-wrap">
                            <div className="st-wechat-logo-item">
                              <img style={{maxWidth: '100%'}} src={item['we-chat-icon']} alt="WeChat Icon" />
                            </div>
                            <div className="st-wechat-logo-text">
                              <span>{item['we-chat-logo-text']}</span>
                            </div>
                          </div>
                          <div className="st-wechat-static-search-wrap">
                            <div className="st-wechat-static-search-icon">
                              {item['search-icon'] && (
                                <div dangerouslySetInnerHTML={{ __html: item['search-icon'] }} />
                              )}
                            </div>
                            <div className="st-wechat-static-search-text">
                              <span>{item['we-chat-search-placeholder']}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="footer-terms">
            {/* Render the terms */}
            <p>{footerContentDataTerms.initialText}</p>
            <ul className="footer-terms-list">
              {footerContentDataTerms.items.map((item, index) => (
                <li key={index}>
                  <a href={item.url} className={ index === footerContentDataTerms.items.length - 1 ? 'optanon-toggle-display' : ''}>
                    {index === 0 ? ` ${(item.text)} |` : index === footerContentDataTerms.items.length - 1 ? (item.text) : `${(item.text)} |`}
                  </a>
                </li>
              ))}
            </ul>
            <div className='footer-terms-space'>
              <br />
              <br />
            </div>
          </div>

          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Footer;
