//
// React.
//
import React from 'react';
//
// Material UI.
//
import { Box } from '@mui/material';
//
// CSS.
//
import './LoadingLucidworksResultItem.css'

const LoadingLucidworksResultItem = () => {
    //
    // Value that defines the number of loading results displayed on the Loading Page,
    // value based on the design
    //
    const itemCount = 8;

    return (
        <div className='LucidworksResultItem'>
            {[...Array(itemCount)].map((_, index) => (
                <Box
                    key={index}
                    sx={{
                        mb: 4,
                    }}
                >
                    <div className='loadingContainer'>
                        <div className='loadingTitle'></div>
                        <div className='loadingBody'>
                            <div className={`loadingBodyleft${index + 1}`}></div>
                            <div className={`loadingBodyright${index + 1}`}></div>
                        </div>
                        <div className={`loadingLink${index + 1}`}></div>
                    </div>
                    {/* Loading - Mobile */}
                    <div className='mobileLoadingContainer'>
                        <div className='loadingLabelMobile'></div>
                        <div className='loadingTitleMobile'></div>
                        <div className='loadingFamilyMobile'></div>
                        <div className='loadingBodyMobile'>
                            <div className={`loadingBodyMobileLine1`}></div>
                            <div className={`loadingBodyMobileLine2`}></div>
                            <div className={`loadingBodyMobileLine3`}></div>
                        </div>
                        <div className={`loadingLinkMobile`}></div>
                        <hr className="borderLoadingMobile" />
                    </div>
                </Box>
            ))}
        </div>
    );
};

export default LoadingLucidworksResultItem;
