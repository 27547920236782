import { createTheme } from '@mui/material'
//
// Create a theme for the UI.
//
const theme = createTheme({
    palette: {
        primary: {
            main: '#03234b'
        },
        secondary: {
            main: '#525a63'
        },
        arrows: {
            main: '#767c84'
        }
    },
    typography: {
        fontFamily: 'Arial,Helvetica,sans-serif,ITCLubalinGraphStd',
        body1: {
            fontSize: '15px',
        },
        body2: {
            color: '#767c84',
            fontSize: '13px',
        },
        //Begin - LucidWorksResultItem Theme Structure
        body1LucidResult: {
            fontFamily: 'Arial',
            color: '#3d4559',
            fontWeight: 'normal',
            fontSize: '13px',
            letterSpacing: 'normal',
            lineHeight: 1.6,
            fontStretch: 'normal',
            fontStyle: 'normal'
        },
        hResultTitle: {
            display: 'block',
            fontFamily: 'Arial',
            fontSize: '17px',
            fontWeight: 'bold',
            letterSpacing: 'normal',
            lineHeight: '1.25',
            fontStretch: 'normal',
            fontStyle: 'normal',
            color: '#03234b'
        },
        //Begin - Feedback Theme Structure
        pFeedback: {
            fontFamily: 'ITCLubalinGraphStd',
            color: '#f7f8fa',
            fontSize: '21px',
            fontWeight: '500',
            letterSpacing: 'normal',
            lineHeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal'
        },
        //End - Feedback Theme Structure
        //Begin - Footer Theme Structure
        h5Footer: {
            fontWeight: 900,
        },
        h6Footer: {
            fontFamily: 'ITCLubalinGraphStd',
            color: '#03234b',
            fontSize: '19px',
            fontWeight: '500',
            paddingBottom: '10px',
            paddingTop: '10px',
            letterSpacing: '1.4px',
            lineHeight: 2.6,
            fontStretch: 'normal',
            fontStyle: 'normal'
        },
        aFooter: {
            fontFamily: 'Arial',
            color: '#525a63',
            fontWeight: 'bold',
            fontSize: '15px',
            letterSpacing: 'normal',
            lineHeight: 1.6,
            fontStretch: 'normal',
            fontStyle: 'normal'
        },
        //End - Footer Theme Structure
        overline: {
            display: 'block',
            fontFamily: 'Arial',
            letterSpacing: 1.6,
            color: '#525a63',
            fontSize: '13px',
            fontWeight: 900,
            lineHeight: 1.6,
        },
        subtitle2: {
            fontFamily: 'Arial',
            color: '#767c84',
            fontSize: '13px',
            lineHeight: 1.5,
            paddingTop: '3px',
        }
    }
});
//
// Responsive styles
//
theme.typography.subtitle2 = {
    ...theme.typography.subtitle2,
    [theme.breakpoints.down('sm')]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '240px',
    },
};

export default theme;