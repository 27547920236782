//
// React.
//
import PropTypes from 'prop-types'
import React from 'react'
//
// Material UI.
//
import { Box } from '@mui/material'
// import { IconButton } from '@mui/material'
// import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
// import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
// import { Typography } from '@mui/material';
//
// Search.
//
// import Constants from '../../config/Constants'
// import Labels from '../../config/Labels'
import Search from '../../actions/Search'
//
// CSS.
//
import './Feedback.css'

class Feedback extends Search {
    //
    // Render the component.
    //
    render = () => {
        return (
            <div className='Feedback'>
                <Box sx={{
                    textAlign: 'center',
                    color: 'white',
                    backgroundColor: 'primary.main',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    height: '240px'
                }}>
                    {/* <Typography variant="pFeedback" component="p">
                        { this.getLabel(Labels.Feedback.Satisfaction) }
                    </Typography>
                    <p>
                        <IconButton
                            sx={{color: 'white'}}
                            onClick={
                                (event) => {
                                    //
                                    // Send the signal to the middleware and track the rating
                                    //
                                    this.sendSignal(Constants.signal.negativeFeedback, this.props.searchParameters)
                                    window.digitalData.search = {
                                        searchEvent: 'searchRating',
                                        searchRating: 'negative',
                                    }
                                    if (window._satellite) {
                                        window._satellite.track('searchRating')
                                    }
                                }
                            }
                        ><ThumbDownOffAltIcon fontSize="large"/></IconButton>
                        <IconButton
                            sx={{color: 'white'}}
                            onClick={
                                (event) => {
                                    //
                                    // Send the signal to the middleware and track the rating
                                    //
                                    this.sendSignal(Constants.signal.positiveFeedback, this.props.searchParameters)
                                    window.digitalData.search = {
                                        searchEvent: 'searchRating',
                                        searchRating: 'positive',
                                    }
                                    if (window._satellite) {
                                        window._satellite.track('searchRating')
                                    }
                                }
                            }
                        ><ThumbUpOffAltIcon fontSize="large"/></IconButton>
                    </p> */}
                </Box>
            </div>
        )
    }
}

Feedback.propTypes = {
    config: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
}

export default Feedback