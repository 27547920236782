//
// React.
//
import PropTypes from 'prop-types'
import React from 'react';
//
// Material UI.
//
import { Checkbox, FormControlLabel, FormGroup, Typography, IconButton} from '@mui/material';
import { RiLoaderLine } from 'react-icons/ri';
//
// Search.
//
import Constants from '../../../../config/Constants';
import Labels from '../../../../config/Labels';
import Filter from '../../../../actions/Filter';
import Util from '../../../../services/Util'

const LoadingCheckboxListFilter = (props) => {
    const filterAction = new Filter(props)
    //
    // Get the active filter values (using a fallback structure) to display on the loading page.
    //
    const activeFilterValues = props.docFilterLoading.activeFilterValues || [
        { value: '1', label: '-- -- --' },
        { value: '2', label: '-- -- --' },
        { value: '3', label: '-- -- --' },
        { value: '4', label: '-- -- --' },
        { value: '5', label: '-- -- --' },
    ]
    //
    // Get the UI language and the filter configuration for displaying the filter title.
    //
    const language = props.searchParameters[Constants.parameter.language] || Constants.defaultParameterValue[Constants.parameter.language];
    const filterName = props.docFilterLoading.name
    const filter = filterAction.getConfiguredFilter(filterName)
    //
    // Render the component.
    //
    return (
        <div className='CheckboxListFilter'>
            <Typography variant='overline' sx={{ pt: '5px', pb: '10px' }}>{ Util.getLabel(filter.label || Labels.CheckboxListFilter.Documentation, language) }</Typography>
            <FormGroup>
                {activeFilterValues.map((option, index) => (
                    <div
                        key={`${index}${option.value}`}
                        style={!props.docFilterLoading.selectedOption ? {
                            height: '2rem',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        } : {}}
                    >
                        <FormControlLabel
                            key={option.value}
                            sx={{
                                color: '#525a63',
                            }}
                            control={
                                props.docFilterLoading.selectedOption
                                    ? <Checkbox
                                        checked={props.docFilterLoading.selectedOption.includes(option.value)}
                                        sx={{
                                            color: '#525a63',
                                            '&.Mui-checked': {
                                                '& + .MuiFormControlLabel-label': {
                                                    color: '#03234b',
                                                    fontWeight: 'bold'
                                                  },
                                              color: '#3cb4e6',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                        }}
                                    />
                                    : <Checkbox
                                        checked={false}
                                    />
                            }
                            label={
                                option.label
                                    ? option.label
                                    : `${option.value} (${new Intl.NumberFormat(Constants.locale[language]).format(option.count || 0)})`
                            }
                        />
                        {
                            option.label
                                && <IconButton
                                    size='small'
                                >
                                    <RiLoaderLine style={{ width: '20px', height: '20px', transform: 'rotate(90deg)' }} />
                                </IconButton>
                        }
                    </div>
                ))}
            </FormGroup>
        </div>
    );
};

LoadingCheckboxListFilter.propTypes = {
    config: PropTypes.object.isRequired,
    docFilterLoading: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
}

export default LoadingCheckboxListFilter;
