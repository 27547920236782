//
// React.
//
import React, { useState, useRef, useEffect } from 'react';
import Constants from '../../../config/Constants';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
//
// CSS.
//
import 'react-loading-skeleton/dist/skeleton.css';
import './VideoResultItem.css';

const VideoResultItem = ({ videoModalInfo, formatDuration, handleVideoClick }) => {
    //
    // State to track whether the modal is open
    //
    const [isOpen, setIsOpen] = useState(false);
    //
    // State to track whether the thumbnail has loaded
    //
    const [thumbnailLoaded, setThumbnailLoaded] = useState(false);
    //
    // State to track when to show the spinner for loading the video
    //
    const [showSpinner, setShowSpinner] = useState(true);
    //
    // State to track whether the title is hidden
    //
    const [isTitleHidden, setIsTitleHidden] = useState(false);
    //
    // Refs to track the video viewer, modal elements, and timer
    //
    const videoViewerRef = useRef(null);
    const modalRef = useRef(null);
    const timerRef = useRef(null);
    //
    // Function to start the timer for hiding the title
    //
    const startHideTitleTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        setIsTitleHidden(false);
        //
        // Duration of visibility before starting to hide
        //
        timerRef.current = setTimeout(() => {
            setIsTitleHidden(true);
        }, 2000);
    };
    //
    // Function to open the modal and initialize the video viewer if not already initialized
    //
    const openModal = () => {
        setIsOpen(true);
        startHideTitleTimer();
        if (!videoViewerRef.current) {
            videoViewerRef.current = new window.s7viewers.VideoViewer({
                containerId: `${videoModalInfo.videoId_s.replace("stelectronics/", "")}`,
                params: {
                    serverurl: "https://assets.st.com/is/image/",
                    contenturl: "https://assets.st.com/is/content/",
                    config: "stelectronics/in_page_full_video_auto",
                    posterimage: `${videoModalInfo.videoId_s}`,
                    videoserverurl: "https://assets.st.com/is/content",
                    asset: `${videoModalInfo.videoId_s}`,
                    'autoplay': "1",
                }
            });
            videoViewerRef.current.init();
        }
    };
    //
    // Function to close the modal
    //
    const closeModal = () => {
        setIsOpen(false);
        clearTimeout(timerRef.current);
        setIsTitleHidden(false);
    };
    //
    // Function to handle 'Escape' key press to close the modal
    //
    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            closeModal();
        }
    };
    //
    // Function to handle click outside of the modal to close it
    //
    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            closeModal();
        }
    };
    //
    // Function to handle mouse movement inside the modal content to reset the hide title timer
    //
    const handleMouseMove = () => {
        startHideTitleTimer();
    };
    //
    // Effect to add and remove event listeners for keydown, mousedown, and mousemove events based on modal state
    //
    useEffect(() => {
        if (isOpen) {
            const modalElement = modalRef.current;
            document.addEventListener('keydown', handleKeyDown);
            document.addEventListener('mousedown', handleClickOutside);
            modalElement.addEventListener('mousemove', handleMouseMove);
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
                document.removeEventListener('mousedown', handleClickOutside);
                modalElement.removeEventListener('mousemove', handleMouseMove);
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    //
    // Effect to control the loading state of the video
    //
    useEffect(() => {
        if (isOpen) {
            //
            // Detect the video loading state
            //
            const observer = new MutationObserver((mutationsList, observer) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        const element = document.getElementById(`${videoModalInfo.videoId_s.replace("stelectronics/", "")}_container`);
                        if (element) {
                            setShowSpinner(false);
                            observer.disconnect();
                        }
                    }
                }
            });
            observer.observe(document.body, { childList: true, subtree: true });
            return () => observer.disconnect();
        }
    }, [isOpen, videoModalInfo.videoId_s]);
    //
    // Validate all the information needed to display the video - Modal
    //
    const validInfoVideoAllInfo = videoModalInfo.videoId_s && videoModalInfo.thumbnailUrl_s && videoModalInfo.title_s

    return (
        <>
            {validInfoVideoAllInfo ? (
                <div>
                    <div className="video-thumbnail-container" onClick={openModal}>
                        {!thumbnailLoaded &&
                            <SkeletonTheme baseColor="#a6adb5" highlightColor="#b7b7b7">
                                <Skeleton width={115} height={64} />
                            </SkeletonTheme>
                        }
                        <img
                            src={videoModalInfo.thumbnailUrl_s}
                            alt="VideoThumbnail"
                            className="video-thumbnail"
                            onLoad={() => setThumbnailLoaded(true)}
                            style={{ display: thumbnailLoaded ? 'block' : 'none' }}
                        />
                        {videoModalInfo.duration_i && <div className="video-duration">{formatDuration(videoModalInfo.duration_i)}</div>}
                        <img src={Constants.images.playCircleLine} alt="PlayCircleLineIcon" className="play-icon" />
                    </div>
                    <div className={`modal-video ${isOpen ? 'open' : 'close'}`}>
                        <div className="modal-content-video" ref={modalRef} style={showSpinner ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : {}}>
                            {showSpinner ? (
                                <div className="spinner"></div>
                            ) : (
                                <div className={`modal-video-title ${isTitleHidden ? 'hidden' : ''}`}>
                                    <span>{videoModalInfo.title_s}</span>
                                </div>
                            )}
                            {/* <span className="close-video" onClick={closeModal}>&times;</span> */}
                            <div id={`${videoModalInfo.videoId_s.replace("stelectronics/", "")}`} className='s7videoviewer'></div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="video-thumbnail-container" onClick={() => { handleVideoClick() }}>
                        {!thumbnailLoaded &&
                            <SkeletonTheme baseColor="#a6adb5" highlightColor="#b7b7b7">
                                <Skeleton width={115} height={64} />
                            </SkeletonTheme>
                        }
                        <img
                            src={videoModalInfo.thumbnailUrl_s || Constants.images.byDefaultVideoThumbnail}
                            alt="VideoThumbnail"
                            className="video-thumbnail"
                            onLoad={() => setThumbnailLoaded(true)}
                            style={{ display: thumbnailLoaded ? 'block' : 'none' }}
                        />
                        {videoModalInfo.duration_i && <div className="video-duration">{formatDuration(videoModalInfo.duration_i)}</div>}
                        <img src={Constants.images.playCircleLine} alt="PlayCircleLineIcon" className="play-icon" />
                    </div>
                </div>
            )}
        </>
    );
};

export default VideoResultItem;
