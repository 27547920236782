//
// React.
//
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types'
//
// Search.
//
import Constants from '../../config/Constants'
import DidYouMean from '../DidYouMean/DidYouMean'
import Labels from '../../config/Labels'
import LoadingLucidworksResultItem from '../ResultItems/LoadingResultItems/LoadingLucidworksResultItem/LoadingLucidworksResultItem';
import Search from '../../actions/Search'
import Util from '../../services/Util'
//
// Material UI.
//
import { Button, styled} from '@mui/material'
import { RiFilter2Line, RiFilter2Fill } from 'react-icons/ri';
//
// CSS.
//
import './Stats.css'

const Stats = (props) => {
    const search = useMemo(() => new Search(props), [props]);
    const language = search.getLanguage()

    useEffect(() => {
        if (props.setResultXrefState) {
            props.setResultXrefState(new Intl.NumberFormat(Constants.locale[language]).format(props.totalResults || 0));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, search]);
    //
    // Render the status information.
    //
    if (props.loading) {
        return (
            <div className='Stats'>
                <span>-- -- ---</span>
            </div>
        )
    }
    //
    // Identify the active result.
    //
    const searchResult = props.searchResult || {}
    const result = searchResult[search.getActiveSource()]?.result || {}
    const facets = searchResult[search.getActiveSource()]?.result?.facets || {}
    const facetNames = Object.keys(facets)
    var activeFilters = []
    props.filter?.getActiveFilters().forEach(filterName => {
        if (
               facetNames.includes(filterName)
            && props.filter?.isDisplayEnabled(filterName)
            && (props.filter?.getActiveValues(filterName).length > 0)
        ) {
            activeFilters.push(filterName)
        }
    })
    //
    // Defines a style button
    //
    const StyledButton = styled(Button)({
        display: 'none',
        '@media screen and (max-width: 1024px)': {
            display: 'inline-flex',
            position: 'absolute',
            right: 0,
            minWidth: '55px',
            height: '40px',
            borderRadius: '15px 0 15px 0'
        },
        '@media screen and (max-width: 820px)': {
            display: 'none',
        },
    })
    //
    // Display the results count for Cross-Reference.
    //
    if (search.getActiveSource() === Constants.resultSource.xRef){
        const searchResultCount = new Intl.NumberFormat(Constants.locale[language]).format(props.totalResults || 0)
        return (
            <div className='Stats'>
                <span className='xReference'>{Util.getLabel(Labels.Stats.CrossReference, language)} </span>
                <span className='xrefResults'>{search.getLabel(Labels.Stats.ResultsFoundXref(searchResultCount, props.searchParameters.queryText))}</span>
                <StyledButton variant='contained' color='primary' onClick={props.handleSidebarOpen}>
                {
                    (activeFilters?.length > 0) 
                        ? <div className='filterContainer'>
                            <div className="notifyCircle"></div>
                            <RiFilter2Fill style={{ width: '24px', height: '24px', color: 'white'}} />
                        </div>
                        : <RiFilter2Line style={{ width: '24px', height: '24px', color: 'white'}} />
                }
                </StyledButton>
            </div>
        )
    }
    //
    // Render the status information, including did you mean if needed.
    //
    if (result) {
        const searchResultCount = new Intl.NumberFormat(Constants.locale[language]).format(result.total || 0)
        return (
            (props.searchParameters[Constants.parameter.didYouMean])
            ? (

                ((searchResultCount !== '0') || (props.searchParameters[Constants.parameter.didYouMean] === Constants.didYouMean.noSuggestionsAvailable))

                    ? <DidYouMean
                        config={props.config}
                        searchParameters={props.searchParameters}
                        searchResult={props.searchResult}
                        setSearchParameters={props.setSearchParameters}
                        setSearchResult={props.setSearchResult}
                        setpreviousFacets={props.setpreviousFacets}
                    />

                    : <div>
                        <div className='Stats'>
                            <span>-- -- ---</span>
                        </div>
                        <LoadingLucidworksResultItem />
                    </div>
            )
            : (

                (searchResultCount !== '0')

                    ? <div className='Stats'>
                        {search.getLabel(Labels.Stats.ResultsFound(searchResultCount))}
                    </div>

                    : <div>
                        <div className='Stats'>
                            <span>-- -- ---</span>
                        </div>
                        <LoadingLucidworksResultItem />
                    </div>
            )
        )
    }

    return <div/>
}

Stats.propTypes = {
    filter: PropTypes.object,
    handleSidebarOpen: PropTypes.func,
    loading: PropTypes.bool,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setResultXrefState: PropTypes.func,
    totalResults: PropTypes.number,
}

export default Stats