//
// React.
//
import PropTypes from 'prop-types'
//
// Material UI.
//
import { Box, Stack, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
//
// Search.
//
import Constants from '../../config/Constants'
import Labels from '../../config/Labels'
import LucidworksResultItem from '../ResultItems/LucidworksResultItem/LucidworksResultItem'
import ProductResultItem from '../ResultItems/ProductResultItem/ProductResultItem'
import Search from '../../actions/Search'
import WebResultItem from '../ResultItems/WebResultItem/WebResultItem'
import WikiRepoFileResultItem from '../ResultItems/WikiRepoFileResultItem/WikiRepoFileResultItem'
import WikiWikiPageResultItem from '../ResultItems/WikiWikiPageResultItem/WikiWikiPageResultItem'
//
// CSS.
//
import './SearchResultsList.css'
//
// Components.
//
import SearchResultsPagination from '../SearchResultsPagination/SearchResultsPagination'
import Stats from '../Stats/Stats'

class SearchResultsList extends Search {
    //
    // Default Render ResultItem base on configuration from the middleware.
    //
    defaultRenderItem(resultItem, resultPosition, type, resultInPage, url, bestBet) {
        switch (type) {
            case Constants.resultItemType.lucidworks:
                return (
                    <LucidworksResultItem
                        config={this.props.config}
                        key={resultItem[Constants.resultItemProperties.url]}
                        position={resultPosition}
                        resultItem={resultItem}
                        resultInPage={resultInPage}
                        searchParameters={this.props.searchParameters}
                        url={url}
                        bestBet={bestBet}
                        isFirstClick={this.props.isFirstClick}
                        setIsFirstClick={this.props.setIsFirstClick}
                    />
                );
            case Constants.resultItemType.google:
                return (
                    <LucidworksResultItem
                        config={this.props.config}
                        key={resultItem[Constants.resultItemProperties.url]}
                        position={resultPosition}
                        resultItem={resultItem}
                        resultInPage={resultInPage}
                        searchParameters={this.props.searchParameters}
                        googleStyle={true}
                        url={url}
                        bestBet={bestBet}
                        isFirstClick={this.props.isFirstClick}
                        setIsFirstClick={this.props.setIsFirstClick}
                    />
                );
            case Constants.resultItemType.product:
                return (
                    <ProductResultItem
                        key={resultItem.url}
                        position={resultPosition}
                        resultItem={resultItem}
                        resultInPage={resultInPage}
                        searchParameters={this.props.searchParameters}
                    />
                );
            case Constants.resultItemType.web:
                return (
                    <WebResultItem
                        key={resultItem.url}
                        position={resultPosition}
                        resultItem={resultItem}
                        resultInPage={resultInPage}
                        searchParameters={this.props.searchParameters}
                    />
                );
            case Constants.resultItemType.wikiLocalFile:
                return (
                    <WikiRepoFileResultItem
                        key={resultItem.uri}
                        position={resultPosition}
                        resultItem={resultItem}
                        resultInPage={resultInPage}
                        searchParameters={this.props.searchParameters}
                    />
                );
            case Constants.resultItemType.wikiWikiPage:
                return (
                    <WikiWikiPageResultItem
                        key={resultItem.uri}
                        position={resultPosition}
                        resultItem={resultItem}
                        resultInPage={resultInPage}
                        searchParameters={this.props.searchParameters}
                    />
                );
            default:
                return (
                    <div key={resultItem.url} style={{ backgroundColor: 'yellow' }}>
                        Unknown result type '{type}' from result source '{this.getActiveSource()}'.
                    </div>
                );
        }
    }
    //
    // Render ResultItem base on configuration from the middleware.
    //
    renderResultItem(resultItem, resultPosition, resultInPage) {
        const { config } = this.props;
        const sourceConfig = config.resultItems[this.getActiveSource()];

        if (Array.isArray(sourceConfig) && sourceConfig.length > 0) {
            const { type, condition, url, bestBet} = sourceConfig[0];

            if (condition) {
                const { fieldname, values } = condition;
                const fieldValue = resultItem[fieldname];

                if (values.includes(fieldValue)) {
                    //
                    // Render the result item with the specified type
                    //
                    switch (type) {
                        case Constants.resultItemType.lucidworks:
                            return (
                                <LucidworksResultItem
                                    config={this.props.config}
                                    key={resultItem[Constants.resultItemProperties.url]}
                                    position={resultPosition}
                                    resultItem={resultItem}
                                    resultInPage={resultInPage}
                                    searchParameters={this.props.searchParameters}
                                    url={url}
                                    bestBet={bestBet}
                                    isFirstClick={this.props.isFirstClick}
                                    setIsFirstClick={this.props.setIsFirstClick}
                                />
                            );
                        case Constants.resultItemType.google:
                            return (
                                <LucidworksResultItem
                                    config={this.props.config}
                                    key={resultItem[Constants.resultItemProperties.url]}
                                    position={resultPosition}
                                    resultItem={resultItem}
                                    resultInPage={resultInPage}
                                    searchParameters={this.props.searchParameters}
                                    googleStyle={true}
                                    url={url}
                                    bestBet={bestBet}
                                    isFirstClick={this.props.isFirstClick}
                                    setIsFirstClick={this.props.setIsFirstClick}
                                />
                            );
                        //
                        // Add cases for other types as needed
                        //
                        default:
                            return (
                                <div key={resultItem.url} style={{ backgroundColor: 'yellow' }}>
                                    Unknown result type '{type}' from result source '{this.getActiveSource()}'.
                                </div>
                            );
                    }
                }else {
                    //
                    // If there is a condition but it does not comply with the specific condition (item) to render
                    //
                    return this.defaultRenderItem(resultItem, resultPosition, resultItem.type, resultInPage)
                }
            } else {
                //
                // If no condition or no match found, use the default type and render
                //
                return this.defaultRenderItem(resultItem, resultPosition, type, resultInPage, url, bestBet)
            }
        }else {
            //
            // If the configuration does not come in list format from the middleware configuration, use the type of each resultItem
            //
            return this.defaultRenderItem(resultItem, resultPosition, resultItem.type, resultInPage)
        }
    }
    //
    // Render the component.
    //
    render = () => {
        //
        // Get the results for the configured source.
        //
        const searchParameters = this.props.searchParameters || {}
        const docsCount = searchParameters[Constants.parameter.docsCount] || Constants.defaultParameterValue[Constants.parameter.docsCount]
        const docsStart = (((searchParameters[this.getPageParameter()] || 1) - 1) * docsCount) + 1
        const searchResult = this.props.searchResult || {}
        const result = searchResult[this.getActiveSource()]?.result || {}
        const totalResults = result.total
        const hits = result.hits || []
        //
        // Render the search results.
        //
        var resultPosition = docsStart - 1
        var resultInPage = 0
        return (
            <div id='search-result-container' className='searchResultContainer'>
                <Box>
                    {
                        //
                        // If the current tab is not the main one, offer a link back.
                        //
                        (this.getActiveSource() !== Constants.resultSource.lucidworks) &&
                        <div>
                            <span
                                className='BackFunction'
                                onClick={
                                    (event) => {
                                        const updatedSearchParameters = {
                                            ...this.props.searchParameters,
                                            [Constants.parameter.activeSource]: Constants.resultSource.lucidworks
                                        }
                                        this.pushHistory(updatedSearchParameters)
                                        this.props.setSearchParameters(updatedSearchParameters)
                                    }
                                }
                            >
                            <Stack direction='row' alignItems='center'>
                                <ArrowBack
                                    sx={{
                                        width: '16px',
                                        height: '16px',
                                        margin: '0px 5px 0px -2px'
                                    }}
                                />
                                <Typography
                                    sx={{textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold'}}
                                >
                                    { this.getLabel(Labels.SearchResultsList.BackToStResults) }
                                </Typography>
                            </Stack>
                        </span>
                        <Stats
                            searchParameters={this.props.searchParameters}
                            searchResult={this.props.searchResult}
                        />
                        </div>
                    }
                    {
                        //
                        // Process all search results.
                        //
                        hits.map((resultItem) => {
                            resultPosition++
                            resultInPage++
                            return this.renderResultItem(resultItem, resultPosition, resultInPage);
                        })
                    }
                </Box>
                {
                    (totalResults > 0) &&
                        <div className='Pagination'>
                            <SearchResultsPagination
                                config={this.props.config}
                                searchParameters={this.props.searchParameters}
                                filterResult={this.props.filterResult}
                                searchResult={this.props.searchResult}
                                setFilterResult={this.props.setFilterResult}
                                setSearchParameters={this.props.setSearchParameters}
                                setSearchResult={this.props.setSearchResult}
                                setpreviousFacets={this.props.setpreviousFacets}
                                setCacheNoResultState={this.props.setCacheNoResultState}
                            />
                        </div>
                }
            </div>
        )
    }
}

SearchResultsList.propTypes = {
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
    setpreviousFacets: PropTypes.func.isRequired,
}

export default SearchResultsList