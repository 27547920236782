//
// React.
//
import PropTypes from 'prop-types'
import React from 'react'
//
// Material UI.
//
import { Box, Typography } from '@mui/material'
//
// Search.
//
import Constants from '../../config/Constants'
import Labels from '../../config/Labels'
import Search from '../../actions/Search'
//
// CSS.
//
import './XrefNotice.css'

class XrefNotice extends Search {
    //
    // Render the component.
    //
    render = () => {
        //
        // Render the link to XRef results if it is not already the active tab and XRef results are available.
        // Colors from https://mui.com/material-ui/customization/default-theme/
        //
        const result = this.props.searchResult[Constants.resultSource.xRef]?.result || {}
        const total = result.total || 0
        //
        // Render if SmallTablet.
        //
        if (this.props.isSmallTablet) {
            return (this.getActiveSource() !== Constants.resultSource.xRef) && (total > 0) && (
                <Box className='XrefNotice'>
                    <Typography variant='body1' sx={{ mt: 1.5}}>
                        <span
                            className='Link'
                            onClick={
                                (event) => {
                                    if (this.props.isLoading === false) {
                                        const updatedSearchParameters = {
                                            ...this.props.searchParameters,
                                            [Constants.parameter.activeSource]: Constants.resultSource.xRef
                                        }
                                        this.pushHistory(updatedSearchParameters)
                                        this.props.setSearchParameters(updatedSearchParameters)
                                        window.scrollTo(0, 0)
                                        if (window._satellite) {
                                            window._satellite.track('pageView')
                                        }
                                    }
                                }
                            }
                        >{this.getLabel(Labels.XrefNotice.LinkTextSTablet(total))}</span>
                    </Typography>
                </Box>
            )
        }

        return (this.getActiveSource() !== Constants.resultSource.xRef) && (total > 0) && (
            <Box className='XrefNoticeContainer' sx={{ display: 'table', border: 1, borderRadius: '6px', borderColor: '#dbdee2', backgroundColor: '#f7f8fa'}}>
                <Box className='XrefNotice' sx={{borderRadius: '6px', backgroundColor: '#fff'}}>
                    <Typography variant='overline'>{ this.getLabel(Labels.XrefNotice.Heading)}</Typography>
                    <Typography variant='body2'>{ this.getLabel(Labels.XrefNotice.Description) }</Typography>
                    <Typography variant='body1' sx={{ mt: 3, mb: 2.5 }}>
                        <span
                            id=''
                            className='Link'
                            onClick={
                                (event) => {
                                    if (this.props.isLoading === false) {
                                        const updatedSearchParameters = {
                                            ...this.props.searchParameters,
                                            [Constants.parameter.activeSource]: Constants.resultSource.xRef
                                        }
                                        this.pushHistory(updatedSearchParameters)
                                        this.props.setSearchParameters(updatedSearchParameters)
                                        window.scrollTo(0, 0)
                                        if (window._satellite) {
                                            window._satellite.track('pageView')
                                        }
                                    }
                                }
                            }
                        >{this.getLabel(Labels.XrefNotice.LinkText(total))}</span>
                    </Typography>
                </Box>
            </Box>
        )
    }
}

XrefNotice.propTypes = {
    config: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
}

export default XrefNotice