//
// React.
//
import PropTypes from 'prop-types'
import React from 'react'
//
// Material UI.
//
import { Box, Tab, Tabs, Typography } from '@mui/material'
//
// Search.
//
import Constants from '../../config/Constants'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import Search from '../../actions/Search'
import SearchResults from '../SearchResults/SearchResults'
import TabPanel from '../TabPanel/TabPanel'
import Util from '../../services/Util'

class SearchResultsPanel extends Search {
    //
    // Render the component.
    //
    render = () => {
        //
        // Identify all sources in the search result.
        //
        const searchParameters = this.props.searchParameters || {}
        const searchResult = this.props.searchResult || {}
        var sources = Object.keys(searchResult) || []
        //
        // Validate the returned results sources against the configured ones.
        //
        const configuredSources = Object.values(this.props.config?.sources?.values || {})
        sources = Util.intersection(sources, configuredSources)
        //
        // Show an error message.
        //
        if (searchResult.error) {
            return (
                <ErrorMessage
                    message={searchResult.error}
                    searchParameters={this.props.searchParameters}
                />
            )
        }
        //
        // Do not render the search result panel if there are no result
        // sources (i.e. no search results).
        //
        if (sources.length === 0) {
            return null
        }
        //
        // For a single result source or there are multiple result sources, but tabs are disabled,
        // render the search result panel without tabs.
        //
        if ((sources.length === 1) || (!this.props.config.searchResults.showTabs)) {
            return (
                <SearchResults
                    cachedFilters={this.props.cachedFilters}
                    cacheNoResult={this.props.cacheNoResult}
                    config={this.props.config}
                    docFilterLoading={this.props.docFilterLoading}
                    filterResult={this.props.filterResult}
                    noResult={this.props.noResult}
                    resultXref={this.props.resultXref}
                    searchParameters={this.props.searchParameters}
                    searchResult={this.props.searchResult}
                    previousFacets={this.props.previousFacets}
                    setCachedFilters={this.props.setCachedFilters}
                    setCacheNoResultState={this.props.setCacheNoResultState}
                    setDocFilterLoadingState={this.props.setDocFilterLoadingState}
                    setFilterResult={this.props.setFilterResult}
                    setResultXrefState={this.props.setResultXrefState}
                    setSearchParameters={this.props.setSearchParameters}
                    setSearchResult={this.props.setSearchResult}
                    setpreviousFacets={this.props.setpreviousFacets}
                    setLoadingState={this.props.setLoadingState}
                    setNoResultState={this.props.setNoResultState}
                    setStateActiveSidebar={this.props.setStateActiveSidebar}
                    setStatePrevTotalResult={this.props.setStatePrevTotalResult}
                    stateActiveSidebar={this.props.stateActiveSidebar}
                    isFirstClick={this.props.isFirstClick}
                    setIsFirstClick={this.props.setIsFirstClick}
                    isFirstClickXRef={this.props.isFirstClickXRef}
                    setIsFirstClickXRef={this.props.setIsFirstClickXRef}
                />
            )
        }
        //
        // Render the search result panel with tabs.
        //
        return (
            <div>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'primary.main',
                    }}
                >
                    <Tabs
                        value={this.getActiveSource()}
                        onChange={
                            (event, value) => {
                                //
                                // Update the search parameters and push them to the browser history.
                                //
                                const updatedSearchParameters = {
                                    ...searchParameters,
                                    [Constants.parameter.activeSource]: value
                                }
                                this.props.setSearchParameters(updatedSearchParameters)
                                this.pushHistory(updatedSearchParameters)
                            }
                        }
                    >
                        {
                            sources.map((source) =>
                                <Tab
                                    key={source}
                                    label={
                                        <Typography textTransform='none'>{ this.getLabel(searchResult[source].label || source) }</Typography>
                                    }
                                    value={source}
                                />
                            )
                        }
                    </Tabs>
                </Box>
                {
                    sources.map((source) =>
                        <TabPanel
                            key={source}
                            index={source}
                            value={this.getActiveSource()}
                        >
                            <SearchResults
                                cachedFilters={this.props.cachedFilters}
                                cacheNoResult={this.props.cacheNoResult}
                                config={this.props.config}
                                docFilterLoading={this.props.docFilterLoading}
                                noResult={this.props.noResult}
                                resultXref={this.props.resultXref}
                                searchParameters={this.props.searchParameters}
                                searchResult={this.props.searchResult}
                                previousFacets={this.props.previousFacets}
                                setCachedFilters={this.props.setCachedFilters}
                                setCacheNoResultState={this.props.setCacheNoResultState}
                                setDocFilterLoadingState={this.props.setDocFilterLoadingState}
                                setLoadingState={this.props.setLoadingState}
                                setNoResultState={this.props.setNoResultState}
                                setResultXrefState={this.props.setResultXrefState}
                                setSearchParameters={this.props.setSearchParameters}
                                setSearchResult={this.props.setSearchResult}
                                setpreviousFacets={this.props.setpreviousFacets}
                                setStateActiveSidebar={this.props.setStateActiveSidebar}
                                setStatePrevTotalResult={this.props.setStatePrevTotalResult}
                                stateActiveSidebar={this.props.stateActiveSidebar}
                                isFirstClick={this.props.isFirstClick}
                                setIsFirstClick={this.props.setIsFirstClick}
                                isFirstClickXRef={this.props.isFirstClickXRef}
                                setIsFirstClickXRef={this.props.setIsFirstClickXRef}
                            />
                        </TabPanel>
                    )
                }
            </div>
        )
    }
}

SearchResultsPanel.propTypes = {
    cachedFilters: PropTypes.object.isRequired,
    cacheNoResult: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    docFilterLoading: PropTypes.object.isRequired,
    filterResult: PropTypes.object.isRequired,
    noResult: PropTypes.bool.isRequired,
    resultXref: PropTypes.any.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setCachedFilters: PropTypes.func.isRequired,
    setCacheNoResultState: PropTypes.func.isRequired,
    setDocFilterLoadingState: PropTypes.func.isRequired,
    setFilterResult: PropTypes.func.isRequired,
    setLoadingState: PropTypes.func.isRequired,
    setNoResultState: PropTypes.func.isRequired,
    setResultXrefState: PropTypes.func.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
    setpreviousFacets: PropTypes.func.isRequired,
    setStateActiveSidebar: PropTypes.func.isRequired,
    setStatePrevTotalResult: PropTypes.func.isRequired,
    stateActiveSidebar: PropTypes.bool.isRequired,
}

export default SearchResultsPanel