//
// React.
//
import PropTypes from 'prop-types'
//
// Material UI.
//
import { IconButton, Tooltip } from '@mui/material'
import { ArrowBack, KeyboardArrowRight } from '@mui/icons-material'
//
// Search.
//
import Constants from '../../../config/Constants'
import Filter from '../../../actions/Filter'
import Labels from '../../../config/Labels'
import Util from '../../../services/Util'

class TreeFilter extends Filter {
    //
    // Construct a new instance.
    //
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props)
    }
    //
    // Render the component.
    //
    render = () => {
        //
        // Get the filter configuration.
        //
        const filterName = this.props.name
        const filter = this.getConfiguredFilter(filterName)
        //
        // Compute the filter level based on the number of filter fields found in
        // the search parameters (if two filters are set, we're at the third level).
        // Get the active and parent filter field names based on the level.
        //
        const filterFields = filter.fields || []
        const filterLevel = Util.intersection(filterFields, Object.keys(this.props.searchParameters)).length
        const activeFilterField = ((filterFields.length > 0) && (filterLevel < filterFields.length)) ? filterFields[filterLevel] : ''
        const parentFilterField = ((filterFields.length > 0) && (filterLevel < filterFields.length - 1) && (filterLevel > 0)) ? filterFields[filterLevel - 1] : ''
        //
        // Prepare the filter content (title and values).
        //
        const filterTitle = (filterLevel === 0) ? this.getLabel(filter.label) : this.props.searchParameters[parentFilterField]
        const filterValues = (activeFilterField === '') ? [] : this.getValues(activeFilterField)
        //
        // Render the tree list filter.
        //
        return (
            <div className='FieldTreeFilter'>
                <h4>
                    {
                        (filterLevel > 0) && <IconButton
                            size='small'
                            onClick={
                                () => {
                                    this.props.setCacheNoResultState('')
                                    //
                                    // Create a new instance of search parameters, delete all filters for
                                    // fields used by this field tree filter instance, and run a search.
                                    //
                                    const updatedSearchParameters = {
                                        ...this.props.searchParameters
                                    }
                                    filterFields.forEach(fieldName => {
                                        delete updatedSearchParameters[fieldName]
                                    })
                                    this.search(updatedSearchParameters)
                                    //
                                    // Send a signal.
                                    //
                                    const removedValues = filterFields.reduce((values, fieldName) => {
                                        const fieldValues = this.props.searchParameters[fieldName]
                                        return values.concat(fieldValues || [])
                                    }, [])
                                    this.sendSignal(Constants.signal.filterRemoved, updatedSearchParameters, [{
                                        name: filterName,
                                        values: removedValues,
                                    }])
                                }
                            }
                        ><Tooltip title={this.getLabel(Labels.TreeListFilter.RemoveFilter)}><ArrowBack/></Tooltip></IconButton>
                    }
                    { filterTitle }
                </h4>
                <ul>
                {
                    filterValues.map(entry => 
                        <li
                            key={ entry.value }
                            style={{ cursor: 'pointer' }}
                            onClick={
                                () => {
                                    this.props.setCacheNoResultState('')
                                    //
                                    // Update the search parameters and run a search.
                                    //
                                    const updatedSearchParameters = this.getUpdatedSearchParameters(activeFilterField, [ entry.value ])
                                    this.search(updatedSearchParameters)
                                    //
                                    // Send a signal.
                                    //
                                    this.sendSignal(Constants.signal.filterAdded, updatedSearchParameters, [{
                                        name: activeFilterField,
                                        values: [ entry.value ],
                                    }])
                                }
                            }
                        >
                            { entry.value } ({entry.count}) <IconButton><Tooltip title={this.getLabel(Labels.TreeListFilter.NextLevel)}><KeyboardArrowRight color="arrows"/></Tooltip></IconButton>
                        </li>
                    )
                }
                </ul>
            </div>
        )
    }
}

TreeFilter.propTypes = {
    name: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    separator: PropTypes.string,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
}

export default TreeFilter