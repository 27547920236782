//
// React.
//
import PropTypes from 'prop-types'
//
// Material UI.
//
import { CheckBox, CheckBoxOutlineBlank, CheckCircleOutline } from '@mui/icons-material'
import { Autocomplete, Checkbox, IconButton, Stack, TextField } from '@mui/material'
//
// Search.
//
import Constants from '../../../config/Constants'
import Filter from '../../../actions/Filter'
import Labels from '../../../config/Labels'
import Util from '../../../services/Util'
//
// CSS.
//
import './ListFilter.css'

class ListFilter extends Filter {
    //
    // Construct a new instance.
    //
    constructor(props) {
        super(props)

        this.state = {
            selectedOptions: null
        }
    }
    //
    // Render the component.
    //
    render = () => {
        //
        // Retrieve the filter name and selected values.
        //
        const filterName = this.props.name
        const filterValues = this.getValues(filterName)
        const filter = this.getConfiguredFilter(filterName)
        const selectedOptions = this.state.selectedOptions || this.props.searchParameters[filterName] || []
        //
        // Do not display a filter which does not have any values.
        //
        if (filterValues.length === 0) {
            return null
        }
        //
        // Decide whether to display an "apply" button, i.e. it multiple selections must be
        // enabled and the selected values have been modified.
        //
        const modified = filter.multiple && (Util.symmetricalDifference(this.getActiveValues(filterName), selectedOptions).length > 0)
        //
        // Render the list filter.
        //
        return (
            this.isDisplayEnabled(filterName) && <div className='ListFilter'>
                <Stack direction='row' justifyContent='space-between'>
                    <Autocomplete
                        id={filterName}
                        disableCloseOnSelect={ filter.multiple }
                        disablePortal
                        fullWidth
                        getOptionLabel={ (option) => this.getOptionLabel(option) }
                        multiple
                        noOptionsText={ this.getLabel(Labels.ListFilter.NoOptions) }
                        options={ filterValues.map(category => category.value) }
                        value={ selectedOptions }
                        renderInput={
                            (params) => (
                                <TextField
                                    {...params}
                                    label={this.getLabel(filter?.label || 'No label')}
                                    variant='standard'
                                />
                            )
                        }
                        renderOption={
                            (props, option, { selected }) => {
                                const label = this.getOptionLabel(option)
                                return (
                                    //
                                    // If multiple selections are allowed, add a checkbox to the option.
                                    //
                                    filter.multiple
                                        ? <li {...props}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlank/>}
                                                checkedIcon={<CheckBox/>}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {label}
                                        </li>
                                        : <li {...props}>{label}</li>
                                    )
                            }
                        }
                        onChange={
                            (event, values, reason, details) => {
                                if (filter.multiple) {
                                    this.setState({
                                        selectedOptions: values,
                                    })
                                } else {
                                    this.props.setCacheNoResultState('')
                                    //
                                    // Call search to update the search results.
                                    //
                                    const updatedSearchParameters = this.getUpdatedSearchParameters(filterName, values, (reason === 'removeOption'))
                                    this.search(updatedSearchParameters)
                                    //
                                    // Send signals.
                                    //
                                    const signal = (reason === 'removeOption') ? Constants.signal.filterRemoved : Constants.signal.filterAdded
                                    this.sendSignal(signal, updatedSearchParameters, [{
                                        name: filterName,
                                        values: values,
                                    }])
                                }
                            }
                        }
                    />
                    {
                        modified && <IconButton
                                color='success'
                                onClick={
                                    (event) => {
                                        this.props.setCacheNoResultState('')
                                        //
                                        // Call search to update the search results.
                                        //
                                        const removedValues = Util.difference(this.getActiveValues(filterName), selectedOptions)
                                        const updatedSearchParameters = this.getUpdatedSearchParameters(filterName, selectedOptions, (removedValues.length > 0))
                                        this.search(updatedSearchParameters)
                                        //
                                        // Send signals.
                                        //
                                        const signal = (removedValues.length > 0) ? Constants.signal.filterRemoved : Constants.signal.filterAdded
                                        this.sendSignal(signal, updatedSearchParameters, [{
                                            name: filterName,
                                            values: (removedValues.length > 0) ? removedValues : selectedOptions,
                                        }])
                                    }
                                }
                        ><CheckCircleOutline/></IconButton>
                    }
                </Stack>
            </div>
        )
    }
}

ListFilter.propTypes = {
    name: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
}

export default ListFilter