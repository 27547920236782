//
// React.
//
import React from 'react';
import PropTypes from 'prop-types'
import sanitizeHtml from 'sanitize-html';
import truncate from 'html-truncate'
//
// Material UI.
//
import { Box, Link, Typography } from '@mui/material'
//
// Search.
//
import ResultItem from '../../../actions/ResultItem'
import VideoResultItem from '../VideoResultItem/VideoResultItem'
//
// CSS.
//
import './LucidworksResultItem.css'
import Labels from '../../../config/Labels'
import Constants from '../../../config/Constants'

class LucidworksResultItem extends ResultItem {
    //
    // Construct a new instance.
    //
    constructor(props) {
        super(props)
        this.state = {
            isFirstClick: 'yes',
        }
        this.titleRef = React.createRef();
    }
    //
    // Get the summary (snippet). Cases:
    //
    //  - Highlighted field available from array (.value property): Highlighted snippet.
    //  - Plain field available from array (no .value property): Plain snippet.
    //  - Plain field available
    //
    getSummary = (resultItem, fieldName) => {
        return (resultItem[fieldName] && resultItem[fieldName][0]?.value)
            ? resultItem[fieldName][0].value
            : (resultItem[fieldName] && Array.isArray(resultItem[fieldName]))
            ? resultItem[fieldName][0]
            : (resultItem[fieldName] && resultItem[fieldName].length > 0)
            ? resultItem[fieldName]
            : null
    }
    //
    // handleVideoClick when clicked in a thumbnail of a video that is not available in Adobe Analytics Media
    //
    handleVideoClick = () => {
        if (this.titleRef.current) {
            this.titleRef.current.click();
        }
    };
    //
    // Render the component.
    //
    render = () => {
        //
        // Custom UI elements.
        //
        const urlUIConfig = (this.props?.url !== undefined) ? this.props.url : true
        const bestBetUIConfig = (this.props?.bestBet !== undefined) ? this.props.bestBet : true
        //
        // Loading information.
        //
        const searchParameters = this.props.searchParameters || {}
        const actualPage = searchParameters[this.getPageParameter()] || 1
        const decimalFormattedPage = actualPage.toString().padStart(2, '0')
        const decimalFormattedPosition = this.props.resultInPage.toString().padStart(2, '0')
        const resultItem = this.props.resultItem
        const language = this.props.searchParameters[Constants.parameter.language] || Constants.defaultParameterValue[Constants.parameter.language]
        //
        // Get the snippet (from language- and non-language-specific fields).
        // Sanitize and truncate the snippet before using it for display.
        //
        let snippet = undefined
        const summaryFields = this.props.config?.summary || []
        summaryFields.find(summaryField => {
            snippet = this.getSummary(resultItem, `${summaryField}_${language}`) || this.getSummary(resultItem, summaryField)
            return snippet
        })
        const sanitizedSnippet = sanitizeHtml(snippet || '', {
            allowedTags: ['em', 'strong'],
        });
        const truncatedSnippet = truncate(sanitizedSnippet, 200);
        //
        // Get additional document properties.
        //
        const title = resultItem[Constants.resultItemProperties.title] || 'no title'
        const url = resultItem[Constants.resultItemProperties.url] || resultItem.id
        const type = resultItem[Constants.resultItemProperties.documentType] || ''
        //
        // Check if the resultItem is a video
        //
        const isVideo = (type === Constants.documentType.video) || false
        //
        // Format filters for analytics.
        //
        const concatenateFilters = (filters) => {
            if (filters.length === 0) {
                return 'no filter'
            }
            //
            // Convert the active filters into plain strings, replacing the
            // internal separator with a pipe symbol.
            //
            const concatenated = filters.map((filter) => {
                if (Array.isArray(filter)) {
                    return filter.map((subFilter) => subFilter.split(Constants.categorySeparator).join('|')).join('|')
                } else {
                    return filter.split(Constants.categorySeparator).join('|')
                }
            }).join('|');
            return concatenated.replace(/\|+/g, '|').replace(/^\|/, '').replace(/\|$/, '')
        }
        //
        // Convert duration from seconds to minutes:seconds format
        //
        const formatDuration = (duration) => {
            const minutes = Math.floor(duration / 60);
            const seconds = duration % 60;
            return `${minutes}:${seconds.toString().padStart(2, '0')}`;
        };
        //
        // Render the result item.
        //
        return (
            <div className={`${(this.props.position === 1 && bestBetUIConfig && this.props?.searchParameters?.[Constants.parameter.didYouMean] === undefined) ? 'firstResult' : 'otherResults'}`}>
                {(this.props.position === 1 && bestBetUIConfig && this.props?.searchParameters?.[Constants.parameter.didYouMean] === undefined ) && (
                    <div className='firstResultTagContainer'>
                        <div className='firstResultTag'></div>
                    </div>
                )}
                {this.props.position === 2 && <hr className="custom-border-top-mobile" />}
                <div className='LucidworksResultItem'>
                    {(this.props.position === 1 && bestBetUIConfig && this.props?.searchParameters?.[Constants.parameter.didYouMean] === undefined) ? (
                        <>
                            <Box sx={this.firstResultBoxProperties}>{this.getLabel(Labels.LucidworksResultItem.BestResult)}</Box>
                            <Box sx={this.boxProperties}>{this.getLabel(Labels.DocumentType[resultItem[Constants.resultItemProperties.documentType]]) || 'no label'}</Box>
                            {(this.props.googleStyle && urlUIConfig) && <Typography variant='subtitle2' style={{color: '#4d5156'}}>{ url }</Typography>}
                        </>
                    ) : (
                        this.props?.searchParameters?.[Constants.parameter.didYouMean] === undefined &&
                        <>
                            <Box sx={this.boxProperties}>{this.getLabel(Labels.DocumentType[resultItem[Constants.resultItemProperties.documentType]]) || 'no label'}</Box>
                            {(this.props.googleStyle && urlUIConfig) && <Typography variant='subtitle2' style={{color: '#4d5156'}}>{ url }</Typography>}
                        </>
                    )}
                    <Typography variant='hResultTitle'>
                        <Link
                            href={url}
                            target='_blank'
                            rel='noreferrer'
                            underline='none'
                            ref={this.titleRef}
                            className={`${(this.props.position === 1 && bestBetUIConfig && this.props?.searchParameters?.[Constants.parameter.didYouMean] === undefined) ? ( this.props.googleStyle ? 'firstResultTitleG' : 'firstResultTitle') : this.props.googleStyle ? 'lucidResultTitleG' : 'lucidResultTitle'}`}
                            onClick={
                                (event) => {
                                    //
                                    // Sends the signal when the user clicks
                                    //
                                    this.sendResultClickedSignal(event)
                                    //
                                    // Feature that allows to track important information neeeded when the user clicks on a result
                                    //
                                    const searchResultContainer = document.getElementById('search-result-container');
                                    if (searchResultContainer) {
                                      searchResultContainer.setAttribute('new-search-setup', `search page:P${decimalFormattedPage}:R${decimalFormattedPosition}`);
                                    }
                                    //
                                    // Preparing filters (Navigation filters)
                                    //
                                    const filters = this.props.config.filters || []
                                    const navigationFilters = Object.keys(this.props.searchParameters)
                                        .filter(filterName => this.isTreeListFilter(filterName))
                                        .map(key => {
                                            const labelEn = filters[key]?.label?.en || filters[key]?.label || key;
                                            const values = Array.isArray(this.props.searchParameters[key]) ? this.props.searchParameters[key] : [this.props.searchParameters[key]];
                                            return values.map(value => `${labelEn}:${value}`).join('|');
                                        });
                                    //
                                    // Preparing filters (Checkbox filters)
                                    //
                                    const CheckboxFilters = Object.keys(this.props.searchParameters)
                                        .filter(filterName => this.isCheckBoxListFilter(filterName))
                                        .map(key => {
                                            const labelEn = filters[key]?.label?.en || filters[key]?.label || key;
                                            const values = Array.isArray(this.props.searchParameters[key]) ? this.props.searchParameters[key] : [this.props.searchParameters[key]];
                                            return values.map(value => `${labelEn}:${value}`).join('|');
                                        });
                                    //
                                    // Perform tracking
                                    //
                                    window.digitalData.search = {
                                        searchEvent: 'searchResultClick',
                                        searchLinkClicked: title,
                                        searchNavigationFilters: concatenateFilters(navigationFilters),
                                        searchCheckboxFilters: concatenateFilters(CheckboxFilters),
                                        searchFirstClick: this.props?.isFirstClick || '',
                                        searchResultPosition: `search page:P${decimalFormattedPage}:R${decimalFormattedPosition}`,
                                    }
                                    if (window._satellite) {
                                        window._satellite.track('searchResultLinkClick')
                                    }
                                    this.props.setIsFirstClick("no")
                                }
                            }
                        dangerouslySetInnerHTML={{ __html: title }}/>
                        <span className="resultItemFamily">
                            {
                                resultItem[Constants.resultItemProperties.productTreeLevel]
                                ? `In ${resultItem[Constants.resultItemProperties.productTreeLevel][0]}`
                                : resultItem[Constants.resultItemProperties.applicationTreeLevel]
                                ? `In ${resultItem[Constants.resultItemProperties.applicationTreeLevel][0]}`
                                : ''
                            }
                        </span>
                    </Typography>
                    <div className={`resultContent ${isVideo ? 'video' : ''}`}>
                        {/* Video Implementation */
                            (isVideo) && (
                                <VideoResultItem videoModalInfo={resultItem} formatDuration={formatDuration} handleVideoClick={this.handleVideoClick} />
                            )
                        }
                        <Typography
                            variant='body1LucidResult'
                            style={this.props.googleStyle ? { color: '#4d5156' } : {}}
                            className={
                                ((this.props.position === 1) && bestBetUIConfig && (this.props?.searchParameters?.[Constants.parameter.didYouMean] === undefined))
                                    ? 'firstResultBody'
                                    : ''
                            }
                        >
                            <span dangerouslySetInnerHTML={{ __html: truncatedSnippet }} className='resultDescription'></span>
                            {/* Video Implementation */
                                (isVideo && resultItem.duration_i) && (
                                    <span className='videoDuration'>{formatDuration(resultItem.duration_i)}</span>
                                )
                            }
                        </Typography>
                    </div>
                    {(!this.props.googleStyle && urlUIConfig && (type !== Constants.documentType.video)) && <Typography variant='subtitle2'>{ url }</Typography>}
                </div>
                {this.props.position !== 1 && <hr className="custom-border-bottom-mobile" />}
            </div>
        )
    }
}

LucidworksResultItem.propTypes = {
    position: PropTypes.number.isRequired,
    resultItem: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
}

export default LucidworksResultItem