//
// React.
//
import PropTypes from 'prop-types'
import React from 'react';
//
// Material UI.
//
import { Button, styled } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { RiFilter2Line, RiFilter2Fill } from 'react-icons/ri';
//
// Search.
//
import Constants from '../../../config/Constants'
import Labels from '../../../config/Labels'
//
// CSS.
//
import '../SearchResults.css'

const BottomMenu = (props) => {
    //
    // Defines a style button for the bottom menu
    //
    const StyledButtonOpen = styled(Button)({
        display: 'none',
        '@media screen and (max-width: 1024px)': {
            display: 'inline-flex',
            position: 'absolute',
            right: 0,
            minWidth: '54px',
            height: '40px',
            borderRadius: '15px 0 15px 0',
            margin: '0 36px 0 0',
        }
    })
    //
    // Get configuration settings.
    //
    const searchResult = props.searchResult || {}
    const facets = searchResult[props.search.getActiveSource()]?.result?.facets || {}
    const facetNames = Object.keys(facets)
    var activeFilters = []
    props.filter?.getActiveFilters().forEach(filterName => {
        if (
               facetNames.includes(filterName)
            && props.filter?.isDisplayEnabled(filterName)
            && (props.filter?.getActiveValues(filterName).length > 0)
        ) {
            activeFilters.push(filterName)
        }
    })

    return (
        <>
            {props.isXref ?
                <>
                    <div className='backArrowContainerSTablet'>
                        <span
                            className='backArrowHeader'
                            onClick={
                                (event) => {
                                    const updatedSearchParameters = {
                                        ...props.searchParameters,
                                        [Constants.parameter.activeSource]: Constants.resultSource.lucidworks
                                    }
                                    props.search.pushHistory(updatedSearchParameters)
                                    props.setSearchParameters(updatedSearchParameters)
                                }
                            }
                        >
                            <ArrowBack
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                    alignSelf: 'center',
                                    color: '#03234b',
                                }}
                            />
                            <span className='BackArrowText'> {props.search.getLabel(Labels.SearchBox.BackArrow)} </span>
                        </span>
                    </div>
                    <StyledButtonOpen variant='contained' color='primary' onClick={props.handleSidebarOpen}>
                        {
                            activeFilters?.length > 0 ?
                                <div className='filterContainer'>
                                    <div className="notifyCircle"></div>
                                    <RiFilter2Fill style={{ width: '24px', height: '24px', color: 'white'}}/>
                                </div>
                            :
                                <RiFilter2Line style={{ width: '24px', height: '24px', color: 'white'}}/>
                        }
                    </StyledButtonOpen>
                </>
                :
                <div className='BottomMenuSTablet'>
                    <div className='backArrowContainerSTablet'>
                        <span
                            className='backArrowHeader'
                            onClick={props.search.handleBackClick}
                            style={{ pointerEvents: 'none' }}
                        >
                            <ArrowBack
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                    alignSelf: 'center',
                                    color: 'rgba(0, 0, 0, 0.26)',
                                }}
                            />
                            <span style={{ color: 'rgba(0, 0, 0, 0.26)'}} className='BackArrowText'> {props.search.getLabel(Labels.SearchBox.BackArrow)} </span>
                        </span>
                    </div>
                    <StyledButtonOpen variant='contained' color='primary' disabled={true}>
                        <RiFilter2Line style={{ width: '24px', height: '24px', color: 'white' }} />
                    </StyledButtonOpen>
                </div>
            }
        </>
    );
};

BottomMenu.propTypes = {
    filter: PropTypes.object,
    isXref: PropTypes.bool,
    search: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
}

export default BottomMenu;
