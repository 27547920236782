import Constants from './Constants'
//
// UI labels: The first level variable names reflect the component class names
// and the second level ones the name of the label used inside the component.
//
class Labels {

    static ActiveFilters = {
        ActiveFilters: {
            [Constants.iso_639_1.chinese]: '当前筛选器',
            [Constants.iso_639_1.english]: 'Filters',
            [Constants.iso_639_1.french]: 'Filtres actifs',
            [Constants.iso_639_1.german]: 'Aktive Filter',
            [Constants.iso_639_1.japanese]: 'フィルター',
        },
        ClearAllFilters: {
            [Constants.iso_639_1.chinese]: '清除所有筛选器',
            [Constants.iso_639_1.english]: 'Clear filters',
            [Constants.iso_639_1.french]: 'Supprimer les filtres',
            [Constants.iso_639_1.german]: 'Filter löschen',
            [Constants.iso_639_1.japanese]: 'クリアフィルター',
        },
    }

    static App = {
        FailedToLoadConfig: {
            [Constants.iso_639_1.chinese]: '加载配置失败',
            [Constants.iso_639_1.english]: 'Failed to load configuration',
            [Constants.iso_639_1.french]: 'Échec du chargement de la configuration',
            [Constants.iso_639_1.german]: 'Konfiguration konnte nicht geladen werden',
            [Constants.iso_639_1.japanese]: '設定のロードに失敗しました',
        }
    }

    static Constants = {
        Language: {
            [Constants.iso_639_1.chinese]: '中文',
            [Constants.iso_639_1.english]: 'English',
            [Constants.iso_639_1.french]: 'Français',
            [Constants.iso_639_1.german]: 'Deutsch',
            [Constants.iso_639_1.japanese]: '日本語',
        }
    }

    static DocumentType = {
        [Constants.documentType.products]: {
            [Constants.iso_639_1.chinese]: '产品',
            [Constants.iso_639_1.english]: 'Products',
            [Constants.iso_639_1.french]: 'Produits',
            [Constants.iso_639_1.german]: 'Produkte',
            [Constants.iso_639_1.japanese]: '製品',
        },
        [Constants.documentType.tools]: {
            [Constants.iso_639_1.chinese]: '工具',
            [Constants.iso_639_1.english]: 'Tools',
            [Constants.iso_639_1.french]: 'Outils',
            [Constants.iso_639_1.german]: 'Werkzeuge',
            [Constants.iso_639_1.japanese]: 'ツール',
        },
        [Constants.documentType.solutions]: {
            [Constants.iso_639_1.chinese]: '解决方案',
            [Constants.iso_639_1.english]: 'Solutions',
            [Constants.iso_639_1.french]: 'Solutions',
            [Constants.iso_639_1.german]: 'Lösungen',
            [Constants.iso_639_1.japanese]: 'ソリューション',
        },
        [Constants.documentType.stm32DeveloperZone]: {
            [Constants.iso_639_1.chinese]: 'STM32开发者社区',
            [Constants.iso_639_1.english]: 'STM32 Developer Zone',
            [Constants.iso_639_1.french]: 'Zone de développeurs STM32',
            [Constants.iso_639_1.german]: 'STM32 Entwicklerzone',
            [Constants.iso_639_1.japanese]: 'STM32 デベロッパーゾーン',
        },
        [Constants.documentType.wiki]: {
            [Constants.iso_639_1.chinese]: '维基',
            [Constants.iso_639_1.english]: 'Wiki',
            [Constants.iso_639_1.french]: 'Wiki',
            [Constants.iso_639_1.german]: 'Wiki',
            [Constants.iso_639_1.japanese]: 'ウィキ',
        },
        [Constants.documentType.resources]: {
            [Constants.iso_639_1.chinese]: '资源',
            [Constants.iso_639_1.english]: 'Resources',
            [Constants.iso_639_1.french]: 'Ressources',
            [Constants.iso_639_1.german]: 'Ressourcen',
            [Constants.iso_639_1.japanese]: 'リソース',
        },
        [Constants.documentType.keyResources]: {
            [Constants.iso_639_1.chinese]: '关键资源',
            [Constants.iso_639_1.english]: 'KeyResources',
            [Constants.iso_639_1.french]: 'Ressources clés',
            [Constants.iso_639_1.german]: 'Schlüsselressourcen',
            [Constants.iso_639_1.japanese]: 'キーリソース',
        },
        [Constants.documentType.applications]: {
            [Constants.iso_639_1.chinese]: '应用程序',
            [Constants.iso_639_1.english]: 'Applications',
            [Constants.iso_639_1.french]: 'Applications',
            [Constants.iso_639_1.german]: 'Anwendungen',
            [Constants.iso_639_1.japanese]: 'アプリケーション',
        },
        [Constants.documentType.partnerProducts]: {
            [Constants.iso_639_1.chinese]: '合作伙伴产品',
            [Constants.iso_639_1.english]: 'Partner Products',
            [Constants.iso_639_1.french]: 'Produits partenaires',
            [Constants.iso_639_1.german]: 'Partnerprodukte',
            [Constants.iso_639_1.japanese]: 'パートナープロダクト',
        },
        [Constants.documentType.software]: {
            [Constants.iso_639_1.chinese]: '软件',
            [Constants.iso_639_1.english]: 'Software',
            [Constants.iso_639_1.french]: 'Logiciel',
            [Constants.iso_639_1.german]: 'Software',
            [Constants.iso_639_1.japanese]: 'ソフトウェア',
        },
        [Constants.documentType.blogs]: {
            [Constants.iso_639_1.chinese]: '博客',
            [Constants.iso_639_1.english]: 'Blogs',
            [Constants.iso_639_1.french]: 'Blogs',
            [Constants.iso_639_1.german]: 'Blogs',
            [Constants.iso_639_1.japanese]: 'ブログ',
        },
        [Constants.documentType.learning]: {
            [Constants.iso_639_1.chinese]: '学习',
            [Constants.iso_639_1.english]: 'Learning',
            [Constants.iso_639_1.french]: 'Apprentissage',
            [Constants.iso_639_1.german]: 'Lernen',
            [Constants.iso_639_1.japanese]: '学習',
        },
        [Constants.documentType.news]: {
            [Constants.iso_639_1.chinese]: '新闻',
            [Constants.iso_639_1.english]: 'News',
            [Constants.iso_639_1.french]: 'Actualités',
            [Constants.iso_639_1.german]: 'Nachrichten',
            [Constants.iso_639_1.japanese]: 'ニュース',
        },
        [Constants.documentType.support]: {
            [Constants.iso_639_1.chinese]: '支持',
            [Constants.iso_639_1.english]: 'Support',
            [Constants.iso_639_1.french]: 'Support',
            [Constants.iso_639_1.german]: 'Unterstützung',
            [Constants.iso_639_1.japanese]: 'サポート',
        },
        [Constants.documentType.other]: {
            [Constants.iso_639_1.chinese]: '其他',
            [Constants.iso_639_1.english]: 'Other',
            [Constants.iso_639_1.french]: 'Autre',
            [Constants.iso_639_1.german]: 'Andere',
            [Constants.iso_639_1.japanese]: 'その他',
        },
        [Constants.documentType.video]: {
            [Constants.iso_639_1.chinese]: '视频',
            [Constants.iso_639_1.english]: 'Video',
            [Constants.iso_639_1.french]: 'Vidéo',
            [Constants.iso_639_1.german]: 'Video',
            [Constants.iso_639_1.japanese]: 'ビデオ',
        },
        [Constants.documentType.events]: {
            [Constants.iso_639_1.chinese]: '活动',
            [Constants.iso_639_1.english]: 'Events',
            [Constants.iso_639_1.french]: 'Événements',
            [Constants.iso_639_1.german]: 'Veranstaltungen',
            [Constants.iso_639_1.japanese]: 'イベント',
        },
        [Constants.documentType.technology]: {
            [Constants.iso_639_1.chinese]: '技术',
            [Constants.iso_639_1.english]: 'Technology',
            [Constants.iso_639_1.french]: 'Technologie',
            [Constants.iso_639_1.german]: 'Technologie',
            [Constants.iso_639_1.japanese]: 'テクノロジー',
        },
    }

    static DidYouMean = {
        DidYouMean: (queryText) => ({
            [Constants.iso_639_1.chinese]: [ '没有找到结果 ', queryText ,'. 你是不是指的是' ],
            [Constants.iso_639_1.english]: [ 'No results found for ', queryText ,'. Did you mean' ],
            [Constants.iso_639_1.french]: [ 'Aucun résultat trouvé pour ', queryText ,'. Vouliez-vous dire' ],
            [Constants.iso_639_1.german]: [ 'Keine Ergebnisse gefunden für ', queryText ,'. Meinten Sie' ],
            [Constants.iso_639_1.japanese]: [ '結果が見つかりません ', queryText ,'. これを意味しましたか' ],
        }),
        DidYouMeanNoResult: (queryText) => ({
            [Constants.iso_639_1.chinese]: [ '没有找到结果 ', queryText ],
            [Constants.iso_639_1.english]: [ 'No results found for ', queryText ],
            [Constants.iso_639_1.french]: [ 'Aucun résultat trouvé pour ', queryText ],
            [Constants.iso_639_1.german]: [ 'Keine Ergebnisse gefunden für ', queryText ],
            [Constants.iso_639_1.japanese]: [ '結果が見つかりません ', queryText ],
        }),
        Result: {
            [Constants.iso_639_1.chinese]: '结果',
            [Constants.iso_639_1.english]: 'result',
            [Constants.iso_639_1.french]: 'résultat',
            [Constants.iso_639_1.german]: 'Ergebnis',
            [Constants.iso_639_1.japanese]: '結果',
        },
        Results: {
            [Constants.iso_639_1.chinese]: '结果',
            [Constants.iso_639_1.english]: 'results',
            [Constants.iso_639_1.french]: 'résultats',
            [Constants.iso_639_1.german]: 'Ergebnisse',
            [Constants.iso_639_1.japanese]: '結果',
        }
    }

    static ErrorMessage = {
        Error: (error) => !error
            ? 'Unknown error'
            : error.status
            ? `${error.statusText} (${error.status}) at ${error.url}`
            : error.toString(),
        ErrorOccured: {
            [Constants.iso_639_1.chinese]: '发生一个错误',
            [Constants.iso_639_1.english]: 'An error occurred',
            [Constants.iso_639_1.french]: 'Une erreur s\'est produite',
            [Constants.iso_639_1.german]: 'Ein Fehler ist aufgetreten',
            [Constants.iso_639_1.japanese]: 'エラーが発生しました',
        }
    }

    static Feedback = {
        Satisfaction: {
            [Constants.iso_639_1.chinese]: '你找到你要找的东西了吗？',
            [Constants.iso_639_1.english]: 'Have you found what you were looking for?',
            [Constants.iso_639_1.french]: 'Avez-vous trouvé ce que vous cherchiez ?',
            [Constants.iso_639_1.german]: 'Haben Sie gefunden, wonach Sie gesucht haben?',
            [Constants.iso_639_1.japanese]: 'お探しのものは見つかりましたか?',
        }
    }

    static ListFilter = {
        NoOptions: {
            [Constants.iso_639_1.chinese]: '无选项',
            [Constants.iso_639_1.english]: 'No options',
            [Constants.iso_639_1.french]: 'Aucune option',
            [Constants.iso_639_1.german]: 'Keine Optionen',
            [Constants.iso_639_1.japanese]: 'オプションなし',
        },
    }

    static LucidworksResultItem = {
        BestResult: {
            [Constants.iso_639_1.chinese]: '最佳结果',
            [Constants.iso_639_1.english]: 'Best Result',
            [Constants.iso_639_1.french]: 'Meilleur Résultat',
            [Constants.iso_639_1.german]: 'Bestes Ergebnis',
            [Constants.iso_639_1.japanese]: 'ベストリザルト',
        }
    }

    static ProductResultItem = {
        Chip: {
            [Constants.iso_639_1.chinese]: '产品',
            [Constants.iso_639_1.english]: 'Product',
            [Constants.iso_639_1.french]: 'Produit',
            [Constants.iso_639_1.german]: 'Produkt',
            [Constants.iso_639_1.japanese]: '製品',
        },
    }

    static SearchBox = {
        EnterSearchTerms: {
            [Constants.iso_639_1.chinese]: '输入搜索词...',
            [Constants.iso_639_1.english]: 'Enter search terms...',
            [Constants.iso_639_1.french]: 'Saisir les termes de recherche...',
            [Constants.iso_639_1.german]: 'Suchbegriffe eingeben...',
            [Constants.iso_639_1.japanese]: '検索条件を入力...',
        },
        Loading: {
            [Constants.iso_639_1.chinese]: '正在加载...',
            [Constants.iso_639_1.english]: 'Loading...',
            [Constants.iso_639_1.french]: 'Chargement...',
            [Constants.iso_639_1.german]: 'Laden...',
            [Constants.iso_639_1.japanese]: '読み込み中...',
        },
        BackArrow: {
            [Constants.iso_639_1.chinese]: '返回',
            [Constants.iso_639_1.english]: 'Back',
            [Constants.iso_639_1.french]: 'Retour',
            [Constants.iso_639_1.german]: 'Zurück',
            [Constants.iso_639_1.japanese]: '戻る',
        },
    }

    static SearchResultsList = {
        BackToStResults: {
            [Constants.iso_639_1.chinese]: '返回到ST结果',
            [Constants.iso_639_1.english]: 'Back to ST results',
            [Constants.iso_639_1.french]: 'Retour aux résultats ST',
            [Constants.iso_639_1.german]: 'Zurück zu den ST-Ergebnissen',
            [Constants.iso_639_1.japanese]: 'STの結果に戻る',
        }
    }

    static SponsoredLinks = {
        YouMayAlsoLike: {
            [Constants.iso_639_1.chinese]: '你可能也会感兴趣...',
            [Constants.iso_639_1.english]: 'You may also like...',
            [Constants.iso_639_1.french]: 'Vous pouvez aussi aimer...',
            [Constants.iso_639_1.german]: 'Das könnte Sie auch interessieren...',
            [Constants.iso_639_1.japanese]: 'こちらもおすすめ...',
        },
    }

    static Stats = {
        ResultsFound: (searchResultCount) => ({
            [Constants.iso_639_1.chinese]: [ '找到 ', searchResultCount, ' 个结果。' ],
            [Constants.iso_639_1.english]: [ 'Showing ', searchResultCount, ' results.' ],
            [Constants.iso_639_1.french]: [ 'Trouvée ', searchResultCount, ' resultats.' ],
            [Constants.iso_639_1.german]: [ searchResultCount, ' Dokumente gefunden.' ],
            [Constants.iso_639_1.japanese]: [ searchResultCount, '件表示' ],
        }),
        ResultsFoundXref: (searchResultCount, queryText) => ({
            [Constants.iso_639_1.chinese]: [ searchResultCount, ' 个 ', queryText, ' 对照检索' ],
            [Constants.iso_639_1.english]: [ searchResultCount, ' cross references for ', queryText ],
            [Constants.iso_639_1.french]: [ searchResultCount, ' références croisées pour ', queryText ],
            [Constants.iso_639_1.german]: [ searchResultCount, ' Querverweise für ', queryText ],
            [Constants.iso_639_1.japanese]: [ searchResultCount, ' ', queryText, ' のクロスリファレンス' ],
        }),
        CrossReference: {
            [Constants.iso_639_1.chinese]: '交叉引用',
            [Constants.iso_639_1.english]: 'X-Reference',
            [Constants.iso_639_1.french]: 'Référence croisée',
            [Constants.iso_639_1.german]: 'Querverweis',
            [Constants.iso_639_1.japanese]: 'クロスリファレンス',
        },
    }

    static TreeListFilter = {
        NextLevel: {
            [Constants.iso_639_1.chinese]: '下一级',
            [Constants.iso_639_1.english]: 'Next level',
            [Constants.iso_639_1.french]: 'Niveau suivant',
            [Constants.iso_639_1.german]: 'Nächste Ebene',
            [Constants.iso_639_1.japanese]: '次のレベル',
        },
        PreviousLevel: {
            [Constants.iso_639_1.chinese]: '上一级',
            [Constants.iso_639_1.english]: 'Previous level',
            [Constants.iso_639_1.french]: 'Niveau précédent',
            [Constants.iso_639_1.german]: 'Vorhergehende Ebene',
            [Constants.iso_639_1.japanese]: '前のレベル',
        },
        RemoveFilter: {
            [Constants.iso_639_1.chinese]: '去除过滤器',
            [Constants.iso_639_1.english]: 'Remove filter',
            [Constants.iso_639_1.french]: 'Retirer le filtre',
            [Constants.iso_639_1.german]: 'Filter löschen',
            [Constants.iso_639_1.japanese]: 'フィルタを削除する',
        },
        ToolsAndSoftware: {
            [Constants.iso_639_1.chinese]: '工具与软件',
            [Constants.iso_639_1.english]: 'Tools And Software',
            [Constants.iso_639_1.french]: 'Outils et logiciels',
            [Constants.iso_639_1.german]: 'Werkzeuge und Software',
            [Constants.iso_639_1.japanese]: 'ツールとソフトウェア',
        },
    }

    static CheckboxListFilter = {
        ShowResults: () => ({
            [Constants.iso_639_1.chinese]: [ '显示结果 ' ],
            [Constants.iso_639_1.english]: [ 'Show results ' ],
            [Constants.iso_639_1.french]: [ 'Afficher les résultats ' ],
            [Constants.iso_639_1.german]: [ 'Ergebnisse anzeigen ' ],
            [Constants.iso_639_1.japanese]: [ '結果を表示する ' ],
        }),
        Documentation: {
            [Constants.iso_639_1.chinese]: '文档',
            [Constants.iso_639_1.english]: 'Documentation',
            [Constants.iso_639_1.french]: 'Documentation',
            [Constants.iso_639_1.german]: 'Dokumentatione',
            [Constants.iso_639_1.japanese]: 'ドキュメンテーション',
        },
        Wiki: {
            [Constants.iso_639_1.chinese]: '维基 ',
            [Constants.iso_639_1.english]: 'Wiki',
            [Constants.iso_639_1.french]: 'Wiki',
            [Constants.iso_639_1.german]: 'Wiki',
            [Constants.iso_639_1.japanese]: 'ウィキ',
        },
        Blog: {
            [Constants.iso_639_1.chinese]: '博客',
            [Constants.iso_639_1.english]: 'Blog',
            [Constants.iso_639_1.french]: 'Blog',
            [Constants.iso_639_1.german]: 'Blog',
            [Constants.iso_639_1.japanese]: 'ブログ',
        },
        News: {
            [Constants.iso_639_1.chinese]: '新闻',
            [Constants.iso_639_1.english]: 'News',
            [Constants.iso_639_1.french]: 'Actualités',
            [Constants.iso_639_1.german]: 'Nachrichten',
            [Constants.iso_639_1.japanese]: 'ニュース',
        },
    }

    static WebResultItem = {
        Chip: {
            [Constants.iso_639_1.chinese]: '网页',
            [Constants.iso_639_1.english]: 'Web page',
            [Constants.iso_639_1.french]: 'Page web',
            [Constants.iso_639_1.german]: 'Webseite',
            [Constants.iso_639_1.japanese]: 'ウェブページ',
        },
    }

    static Pagination = {
        Page: {
            [Constants.iso_639_1.chinese]: '页面',
            [Constants.iso_639_1.english]: 'Page',
            [Constants.iso_639_1.french]: 'Page',
            [Constants.iso_639_1.german]: 'Seite',
            [Constants.iso_639_1.japanese]: 'ページ',
        },
        Of: {
            [Constants.iso_639_1.chinese]: '的',
            [Constants.iso_639_1.english]: 'Of',
            [Constants.iso_639_1.french]: 'De',
            [Constants.iso_639_1.german]: 'Von',
            [Constants.iso_639_1.japanese]: 'の',
        },
    }


    static WikiRepoFileResultItem = {
        Chip: {
            [Constants.iso_639_1.chinese]: '存储库文件',
            [Constants.iso_639_1.english]: 'Repository file',
            [Constants.iso_639_1.french]: 'Fichier de dépôt',
            [Constants.iso_639_1.german]: 'Repository-Datei',
            [Constants.iso_639_1.japanese]: 'リポジトリファイル',
        },
    }

    static WikiWikiPageResultItem = {
        Chip: {
            [Constants.iso_639_1.chinese]: '维基页面',
            [Constants.iso_639_1.english]: 'Wiki page',
            [Constants.iso_639_1.french]: 'Page wiki',
            [Constants.iso_639_1.german]: 'Wikiseite',
            [Constants.iso_639_1.japanese]: 'ウィキのページ',
        },
    }

    static XrefNotice = {
        Heading: {
            [Constants.iso_639_1.chinese]: '交叉引用',
            [Constants.iso_639_1.english]: 'Cross References',
            [Constants.iso_639_1.french]: 'Références Croisées',
            [Constants.iso_639_1.german]: 'Querverweise',
            [Constants.iso_639_1.japanese]: 'クロスリファレンス',
        },
        Description: {
            [Constants.iso_639_1.chinese]: 'Xref 是交叉引用的简称，是相关产品之间的连接，可帮助您在搜索时发现更多选项。当您搜索特定商品时，我们的搜索引擎会使用 Xref 根据各种标准查找类似或相关的产品。',
            [Constants.iso_639_1.english]: 'Xref, short for cross-references, are connections between related products that helps you discover additional options while searching. When you search for a specific item, our search engine uses Xref to find similar or relevant products based on various criteria.',
            [Constants.iso_639_1.french]: 'Les Xref, abréviation de cross-references, sont des connexions entre des produits apparentés qui vous aident à découvrir des options supplémentaires lors de vos recherches. Lorsque vous recherchez un article spécifique, notre moteur de recherche utilise les références croisées pour trouver des produits similaires ou pertinents en fonction de divers critères.',
            [Constants.iso_639_1.german]: 'Xref, kurz für Querverweise, sind Verbindungen zwischen verwandten Produkten, die Ihnen bei der Suche nach zusätzlichen Optionen helfen. Wenn Sie nach einem bestimmten Artikel suchen, verwendet unsere Suchmaschine Xref, um ähnliche oder relevante Produkte auf der Grundlage verschiedener Kriterien zu finden.',
            [Constants.iso_639_1.japanese]: 'Xref (クロスレファレンス) とは、クロスリファレンスの略で、関連商品間の接続のことです。お客様が特定の商品を検索する際、弊社の検索エンジンはXrefを使用し、様々な条件に基づいて類似商品や関連商品を検索します。',
        },
        LinkText: (searchResultCount) => ({
            [Constants.iso_639_1.chinese]: [ '显示 ', searchResultCount, ' 个交叉引用' ],
            [Constants.iso_639_1.english]: [ 'show ', searchResultCount, ' cross references' ],
            [Constants.iso_639_1.french]: [ 'montre', searchResultCount, ' références croisées' ],
            [Constants.iso_639_1.german]: [ 'zeige ', searchResultCount, ' Querverweise an' ],
            [Constants.iso_639_1.japanese]: [ searchResultCount, 'のクロスリファレンスを表示' ],
        }),
        LinkTextSTablet: (searchResultCount) => ({
            [Constants.iso_639_1.chinese]: [ searchResultCount, ' 个交叉引用' ],
            [Constants.iso_639_1.english]: [ searchResultCount, ' cross references' ],
            [Constants.iso_639_1.french]: [ searchResultCount, ' références croisées' ],
            [Constants.iso_639_1.german]: [ searchResultCount, ' Querverweise an' ],
            [Constants.iso_639_1.japanese]: [ searchResultCount, 'のクロスリファレンスを表示' ],
        })
    }

    static XrefResultTable = {
        Chip: {
            [Constants.iso_639_1.chinese]: '参照',
            [Constants.iso_639_1.english]: 'Cross Reference',
            [Constants.iso_639_1.french]: 'Référence Croisée',
            [Constants.iso_639_1.german]: 'Querverweis',
            [Constants.iso_639_1.japanese]: '相互参照',
        },
        HeaderName: {
            Difference: {
                [Constants.iso_639_1.chinese]: '差异',
                [Constants.iso_639_1.english]: 'Difference',
                [Constants.iso_639_1.french]: 'Différence',
                [Constants.iso_639_1.german]: 'Unterschied',
                [Constants.iso_639_1.japanese]: '違い',
            },
            ProductCategory: {
                [Constants.iso_639_1.chinese]: '产品类别',
                [Constants.iso_639_1.english]: 'Product Category',
                [Constants.iso_639_1.french]: 'Catégorie de Produit',
                [Constants.iso_639_1.german]: 'Produktkategorie',
                [Constants.iso_639_1.japanese]: '製品カテゴリー',
            },
            Replacement: {
                [Constants.iso_639_1.chinese]: '更换',
                [Constants.iso_639_1.english]: 'Replacement',
                [Constants.iso_639_1.french]: 'Remplacement',
                [Constants.iso_639_1.german]: 'Ersatz',
                [Constants.iso_639_1.japanese]: '交換',
            },
            STPartNumber: {
                [Constants.iso_639_1.chinese]: 'ST 部件编号',
                [Constants.iso_639_1.english]: 'ST Part Number',
                [Constants.iso_639_1.french]: 'ST Numéro de Pièce',
                [Constants.iso_639_1.german]: 'ST-Teilenummer',
                [Constants.iso_639_1.japanese]: 'ST品番',
            },
            VendorName: {
                [Constants.iso_639_1.chinese]: '供应商名称',
                [Constants.iso_639_1.english]: 'Vendor Name',
                [Constants.iso_639_1.french]: 'Nom du Vendeur',
                [Constants.iso_639_1.german]: 'Name des Anbieters',
                [Constants.iso_639_1.japanese]: 'ベンダー名',
            },
            VendorPartNumber: {
                [Constants.iso_639_1.chinese]: '供应商零件编号',
                [Constants.iso_639_1.english]: 'Vendor Part Number',
                [Constants.iso_639_1.french]: 'Numéro de Pièce du Fournisseur',
                [Constants.iso_639_1.german]: 'Lieferanten-Teilenummer',
                [Constants.iso_639_1.japanese]: 'ベンダー品番',
            },
        }
    }
}

export default Labels