//
// React.
//
import PropTypes from 'prop-types'
import { useState, useEffect  } from 'react';
//
// Material UI.
//
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
//
// Material UI and React Icons.
//
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { RiSortDesc } from 'react-icons/ri';
import { RiSortAsc } from 'react-icons/ri';
import TablePagination from '@mui/material/TablePagination';
//
// Components.
//
import Constants from '../../../config/Constants';
import Labels from '../../../config/Labels'
import Stats from '../../Stats/Stats'
import Util from '../../../services/Util'
//
// Search.
//
import Search from '../../../actions/Search'
//
// CSS.
//
import './XrefResultTable.css'

const XrefResultTable = ({ resultItem, searchParameters, searchResult, handleSidebarOpen, setResultXrefState, filter, configFilters, isFirstClickXRef, setIsFirstClickXRef }) => {
  //
  // States for the select dropdown and pagination.
  //
  const [isOpen, setIsOpen] = useState(false);
  const [actualPage, setActualPage] = useState(0);
  const [useFlex, setUseFlex] = useState(window.innerWidth > 820);

  useEffect(() => {
    const handleResolutionChange = () => {
      setUseFlex(window.innerWidth > 820);
    };

    handleResolutionChange();

    window.addEventListener('resize', handleResolutionChange);

    return () => {
      window.removeEventListener('resize', handleResolutionChange);
    };
  }, []);

  const search = new Search(searchParameters);
  //
  // Logic for the select dropdown: Close.
  //
  const handleClose = () => {
    setIsOpen(false);
  };
  //
  // Logic for the select dropdown: Open.
  //
  const handleOpen = () => {
    setIsOpen(true);
  };
  //
  // Logic for the pagination
  //
  const handlePageChange = (event, page) => {
    //
    // Check if there's a vertical scroll
    //
    const needsScroll = window.scrollY > 0;
    //
    // If scrolling is needed, perform it and then change the page
    //
    if (needsScroll) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      setTimeout(() => {
        setActualPage(page);
      }, (page + 1) === totalPages ? 500 : 100);
    } else {
      //
      // If no scrolling is needed, simply change the page
      //
      setActualPage(page);
    }
  };
  //
  // Filters that may be set be the user and corresponding field names.
  //
  const filters = [
    { parameter: Constants.xRefFilter.manufacturer, field: Constants.xRefColumn.vendorName },
    { parameter: Constants.xRefFilter.replacement, field: Constants.xRefColumn.replacement },
  ]
  //
  // Initialize pagination.
  //
  const itemsPerPage = 25;
  //
  // Filter the search result if filters have been selected by the user.
  //
  const filteredResultItem = filters.reduce((filtered, filter) => {
    const activeValues = searchParameters[filter.parameter] || [];
    if (activeValues.length > 0) {
      return filtered.filter(row => activeValues.includes(row[filter.field]));
    }
    return filtered;
  }, resultItem);
  const totalPages = Math.ceil(filteredResultItem.length / itemsPerPage);
  const range = Array.from({ length: totalPages }, (_, index) => index + 1);
  const startIndex = actualPage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredResultItem.length);
  //
  // Reset actualPage when searchParameters change
  //
  useEffect(() => {
    setActualPage(0);
  }, [searchParameters]);
  //
  // Get number of digits in "totalPages"
  //
  const countDigits = (totalPages + 1).toString().length;
  //
  // Value use to increase the size of the "select"
  // based on the number of digits in "totalPages"
  //
  const muiTablePaginationSelectWidth = 65 + Math.max(0, (countDigits - 2) * (countDigits - 2 >= 3 ? 8 : 6));
  //
  // Get current language
  //
  const language = searchParameters[Constants.parameter.language] || Constants.defaultParameterValue[Constants.parameter.language];
  //
  // Format filters for
  //
  const concatenateFilters = (filters) => {
    if (filters.length === 0) {
        return 'no filter'
    }
    const concatenated = filters.map((filter) => {
        if (Array.isArray(filter)) {
          return filter.map(subFilter => subFilter.split(Constants.categorySeparator).join(':')).join(':')
        } else {
          return filter.split(Constants.categorySeparator).join(':')
        }
    }).join(':')

    return concatenated.replace(/:+/g, ':').replace(/:$/, '')
  }
  //
  // Event handler for cell click in stPartNumber column
  //
  const handleStPartNumberCellClick = (value, searchResultPosition) => {
    //
    // Preparing filters (Checkbox filters)
    //
    const CheckboxFilters = Object.keys(searchParameters)
    .filter(key => (key.includes(Constants.xRefFilter.manufacturer) || key.includes(Constants.xRefFilter.replacement)))
    .map(key => {
      const labelEn = configFilters[key]?.label?.en || configFilters[key]?.label || key;
      const values = Array.isArray(searchParameters[key]) ? searchParameters[key] : [searchParameters[key]];
      const filteredValues = values.filter(value => value !== '');
      return filteredValues.map(value => `${labelEn}:${value}`).join('|');
    });
    //
    // Perform tracking
    //
    window.digitalData.search = {
      searchEvent: 'crossReferenceClicks',
      searchLinkClicked: value,
      searchNavigationFilters: 'no filter',
      searchCheckboxFilters: concatenateFilters(CheckboxFilters),
      searchFirstClick: isFirstClickXRef || '',
      searchResultPosition: searchResultPosition
    }
    if (window._satellite) {
        window._satellite.track('crossReferenceClicks')
    }
    setIsFirstClickXRef("no")
  };
  //
  // Setting the columns
  //
  const columns = [
    { field: Constants.xRefColumn.vendorPartNumber, headerName: Util.getLabel(Labels.XrefResultTable.HeaderName.VendorPartNumber, language), flex: useFlex ? 1 : undefined, minWidth: useFlex ? undefined : 180},
    { field: Constants.xRefColumn.stPartNumber, headerName: Util.getLabel(Labels.XrefResultTable.HeaderName.STPartNumber, language), flex: useFlex ? 1 : undefined, minWidth: useFlex ? undefined : 120,
      renderCell: (params) => (
        <div className='stPartNumber'>
        {params.row.url ? (
          <a href={params.row.url} target="_blank" rel="noopener noreferrer" onClick={() => {
            const decimalFormattedPage = (actualPage + 1).toString().padStart(2, '0');
            const decimalFormattedPosition = params.row.position.toString().padStart(2, '0');
            const xRefResultTable = document.getElementById('x-ref-result-table');
            if (xRefResultTable) {
              xRefResultTable.setAttribute('new-search-setup', `x-ref:P${decimalFormattedPage}:R${decimalFormattedPosition}`);
            }
            const searchResultPosition = `x-ref:P${decimalFormattedPage}:R${decimalFormattedPosition}`
            handleStPartNumberCellClick(params.value, searchResultPosition)
          }}>
            {params.value}
          </a>
        ) : (
          params.value
        )}
        </div>
      ),
    },
    { field: Constants.xRefColumn.replacement, headerName: Util.getLabel(Labels.XrefResultTable.HeaderName.Replacement, language), flex: useFlex ? 1 : undefined, minWidth: useFlex ? undefined : 150},
    { field: Constants.xRefColumn.productCategory, headerName: Util.getLabel(Labels.XrefResultTable.HeaderName.ProductCategory, language), flex: useFlex ? 1 : undefined, minWidth: useFlex ? undefined : 150},
    { field: Constants.xRefColumn.vendorName, headerName: Util.getLabel(Labels.XrefResultTable.HeaderName.VendorName, language), flex: useFlex ? 1 : undefined, minWidth: useFlex ? undefined : 120},
    { field: Constants.xRefColumn.difference, headerName: Util.getLabel(Labels.XrefResultTable.HeaderName.Difference, language), flex: useFlex ? 1 : undefined, minWidth: useFlex ? undefined : 180,
    renderCell: (params) => (
      <div className='difference'>
        {params.value}
      </div>
    ),
    },
  ];
  //
  // Add an id to each row in the search results.
  //
  let counter = 0;
  const rows = filteredResultItem.slice(startIndex, endIndex).map((item, index) => {
    counter++;
    return { ...item, id: startIndex + index + 1, position: counter };
  });
  //
  // If rows is empty then actualPage 0
  //
  if (rows.length === 0) {
    setActualPage(0);
  }
  //
  // Custom icon for sorting
  //
  const CustomSortIcon = ({ direction }) => {
    return direction === 'asc' ? <RiSortAsc /> : <RiSortDesc />;
  };

  return (
    <div className='xrefTableContainer'>
        <Stats
            filter={filter}
            handleSidebarOpen={handleSidebarOpen}
            searchParameters={searchParameters}
            searchResult={searchResult}
            setResultXrefState={setResultXrefState}
            totalResults={filteredResultItem.length}
        />
        <div id='x-ref-result-table' className='XrefResultTable'>
        {/* Render the component's UI using the extracted props */}
          <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
              className='custom-datagrid'
              autoHeight
              rows={rows}
              columns={columns}
              columnHeaderHeight = {36}
              slots={{
                columnSortedAscendingIcon: () => (
                  <CustomSortIcon direction="asc" />
                ),
                columnSortedDescendingIcon: () => (
                  <CustomSortIcon direction="desc" />
                )
              }}
              disableRowSelectionOnClick
              sx={{
                borderStyle: 'none',
                '& .MuiDataGrid-virtualScroller': {
                  overflow: useFlex ? 'hidden': 'auto',
                },
                '& .MuiDataGrid-footerContainer': {
                  borderTopStyle: 'none',
                },
                '& .MuiDataGrid-cell': {
                  color: '#525a63',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#eeeff1',
                  borderLeft: 'solid 4px #a6adb5;',
                  borderBottom :'solid 2px #a6adb5;',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus-within': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                  justifyContent: 'space-between',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  color: '#525a63',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                  width: 'auto',
                },
              }}
              disableColumnMenu
              hideFooter
            />
          </Box>
          <div className='Pagination'>
            <div className='pagination-container'>
              <style>
              {
                    //
                    // This section is responsible for adding or removing padding based on whether the back arrow
                    // is displayed in the pagination (It is displayed when the "actualPage" is greater than 1)
                    // Also sets the size of the required for the "select".
                    // It corresponds to a hard customization of the Mui library to meet the design requirements.
                    //
                }
                {`
                    .MuiTablePagination-root .MuiToolbar-root {
                        padding-left: ${((actualPage + 1) > 1 ) ? 50 : 0}px;
                    }
                    .MuiTablePagination-root .mui-select {
                        width: ${muiTablePaginationSelectWidth}px;
                    }
                    .MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input {
                        min-width: ${muiTablePaginationSelectWidth + 1}px;
                    }
                `}
              </style>
              <TablePagination
                component="div"
                page={range.length === 1 ? 0 : actualPage}
                count={filteredResultItem.length}
                rowsPerPage={itemsPerPage}
                rowsPerPageOptions={
                  range.length === 1
                    ? [...range, { value: -1, label: 'All' }]
                    : range.map(value => ({
                      value,
                      label: new Intl.NumberFormat(Constants.locale[search.getLanguage()]).format(value),
                    }))
                }
                onPageChange={handlePageChange}
                onRowsPerPageChange={(event) => {
                  handlePageChange(null, event.target.value - 1);
                }}
                disabled={range.length === 1}
                slotProps={{
                    select: {
                        value: range.length === 1 ? '1' : actualPage + 1,
                        IconComponent: KeyboardArrowDownIcon,
                        open: isOpen,
                        onOpen: handleOpen,
                        onClose: handleClose,
                        className: `mui-select ${isOpen ? 'open' : ''} ${
                          range.length !== 1 ? 'select-hover-effect' : ''
                        }`,
                    },
                    actions: {
                        previousButton: {
                            style: {
                              display: actualPage + 1 === 1 ? 'none' : 'inline-flex',
                            },
                            className: 'arrow-hover-effect',
                        },
                    }
                }}
                labelDisplayedRows={() => (
                  <span>
                    <strong>{Util.getLabel(Labels.Pagination.Of, language)}</strong>{' '}
                    {`\u00A0 ${new Intl.NumberFormat(Constants.locale[search.getLanguage()]).format(totalPages)}`}
                  </span>
                )}
                labelRowsPerPage={<strong>{Util.getLabel(Labels.Pagination.Page, language)}</strong>}
              />
            </div>
          </div>
      </div>
    </div>
    );
}

XrefResultTable.propTypes = {
    resultItem: PropTypes.array.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
}

export default XrefResultTable