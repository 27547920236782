//
// React.
//
import PropTypes from 'prop-types'
import React from 'react'
//
// Material UI.
//
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
//
// Search.
//
import Filter from '../../../actions/Filter'

class SourceFilter extends Filter {
    //
    // Construct a new instance.
    //
    constructor(props) {
        super(props)

        this.categoryLabels = this.props.config.filters[this.collectionFacet]?.categoryLabels || {}
        this.separatorChar = '_'

        this.state = {
            selectedOption: 'all'
        }

        this.collectionFacet = 'collection'
        this.inputLabel = `${this.collectionFacet}Label`
    }
    //
    // Get a list of categories consisting of the source name and the categories from
    // the given facet (inside this source), e.g., <source>_<category>. If the facet
    // does not exist or is empty, simply use the source name, e.g., <source> and its
    // total result count. The categories are sorted by count in descending order.
    //
    getCategories = () => {
        const categories = []
        this.getSources().forEach(source => {
            const sourceCategories = this.getValues(this.collectionFacet, source).map(category => ({ ...category, value: `${source}${this.separatorChar}${category.value}`}))
            // https://stackoverflow.com/questions/60309975/using-spread-syntax-with-ternary-operator-inside-object-literal
            categories.push(...(sourceCategories.length === 0) ? [ { value: source, count: this.props.searchResult[source]?.result?.total || 0} ] : sourceCategories)
        })
        return categories.sort((categoryA, categoryB) => (categoryB.count - categoryA.count))
    }
    //
    // Get the label for an option, including the document count, e.g., 'Word Document (132)' or 'img (23)'.
    //
    getOptionLabel = (option) => {
        const [ source, categoryValue ] = option.split(this.separatorChar)
        //
        // If a category value is part of the option value, use it to locate the
        // number of documents found for this category.
        //
        if (categoryValue) {
            const category = this.getValues(this.collectionFacet, source).find(category => (category.value === categoryValue))
            const label = this.getLabel(this.categoryLabels[categoryValue]) || categoryValue
            return `${label} (${category.count})`
        }
        //
        // Without a category value, display the total number of results for the
        // result source.
        //
        const searchResult = this.props.searchResult[source]
        const total = searchResult?.result?.total
        const resultCount = (total) ? ` (${total})` : ''
        return `${this.getLabel(searchResult?.label)}${resultCount}`
    }
    //
    // Get a list of options, i.e., the category values without the count.
    //
    getOptions = (categories) => {
        return (categories || this.getCategories()).map(category => category.value)
    }
    //
    // Render the component.
    //
    render = () => {
        //
        // If not configured to be shown, don't do it.
        //
        if (!this.props.config.searchResults.showSourceFilter) {
            return null
        }
        //
        // Count the number of results for all source/collection combinations.
        //
        const categories = this.getCategories()
        const allResultsCount = categories.reduce((total, category) => total + category.count, 0)
        //
        // Render the source filter.
        //
        return (
            <div>
                <FormControl variant='standard' style={{width:'100%'}}>
                    <InputLabel id={this.inputLabel}>{this.getLabel('Source[de]Quelle[fr]Source')}</InputLabel>
                    <Select
                        labelId={this.inputLabel}
                        id={this.collectionFacet}
                        value={this.state.selectedOption}
                        onChange={
                            (event) => {
                                this.setState({
                                    selectedOption: event.target.value
                                })
                                this.search({
                                    ...this.props.searchParameters,
                                    [this.collectionFacet]: event.target.value
                                })
                            }
                        }
                    >
                        <MenuItem value='all'>{`${this.getLabel('All[de]Alle[fr]Tous')} (${allResultsCount})`}</MenuItem>
                        {
                            this.getOptions(categories).map(
                                option => <MenuItem
                                    key={option}
                                    value={option}
                                >
                                    {this.getOptionLabel(option)}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </div>
        )
    }
}

SourceFilter.propTypes = {
    config: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
}

export default SourceFilter