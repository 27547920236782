//
// React.
//
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
//
// Search.
//
import ActiveFilters from '../Filters/ActiveFilters/ActiveFilters'
import BottomMenu from './BottomMenu/BottomMenu'
import Constants from '../../config/Constants'
import Feedback from '../Feedback/Feedback'
import Filter from '../../actions/Filter'
import FilterPanel from '../FilterPanel/FilterPanel'
import Labels from '../../config/Labels'
import LoadingCheckboxListFilter from '../Filters/LoadingFilters/LoadingCheckboxListFilter/LoadingCheckboxListFilter';
import LoadingTreeListFilter from '../Filters/LoadingFilters/LoadingTreeListFilter/LoadingTreeListFilter';
import LoadingXrefNotice from '../XrefNotice/LoadingXrefNotice/LoadingXrefNotice';
import Search from '../../actions/Search'
import SearchResultsList from '../SearchResultsList/SearchResultsList'
import SearchResultsTable from '../SearchResultsTable/SearchResultsTable'
// import SponsoredLinks from '../SponsoredLinks/SponsoredLinks'
import Stats from '../Stats/Stats'
import SourceFilter from '../Filters/SourceFilter/SourceFilter'
import XrefNotice from '../XrefNotice/XrefNotice'
//
// CSS.
//
import './SearchResults.css'
//
// Material UI.
//
import { IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import { Button, styled} from '@mui/material'
import { RiFilter2Line, RiFilter2Fill } from 'react-icons/ri';
import { ArrowBack } from '@mui/icons-material'

const SearchResults = (props) => {
    //
    // Sidebar Logic
    //
    const [sidebarOpen, setSidebarOpen] = useState(props.stateActiveSidebar);

    const [sidebarStyles, setSidebarStyles] = useState(false);
    //
    // Function to handle sidebar logic (Open)
    //
    const handleSidebarOpen = () => {
        document.body.classList.add('body-no-scroll');
        setTimeout(() => {
            props.setStateActiveSidebar(true)
        }, 300);
        setSidebarOpen(true);
        setSidebarStyles(true)
    };
    //
    // Function to handle sidebar logic (Close)
    //
    const handleSidebarClose = () => {
        document.body.classList.remove('body-no-scroll');
        props.setStateActiveSidebar(false)
        setSidebarOpen(false);
    };

    useEffect(() => {
        //
        // Keep sidebar styles until closed
        //
        if (!sidebarOpen) {
            setTimeout(() => {
                setSidebarStyles(false);
            }, 300);
        }
        if (props.stateActiveSidebar) {
            setSidebarStyles(true)
        }
        //
        // Handle Resolution Change
        //
        const handleResolutionChange = () => {
          if (window.innerWidth > 820) {
            props.setStateActiveSidebar(false)
            setSidebarStyles(false)
            document.body.classList.remove('body-no-scroll');
          }
          if (window.innerWidth > 1024 && search.getActiveSource() === Constants.resultSource.xRef) {
            handleSidebarClose()
          }
          if (window.innerWidth <= 820 && sidebarOpen === true) {
            setSidebarStyles(true)
            document.body.classList.add('body-no-scroll');
          }
        };
        window.addEventListener('resize', handleResolutionChange);
        return () => {
          window.removeEventListener('resize', handleResolutionChange);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebarOpen]);

    const search = new Search(props);

    const filter = new Filter(props);
    //
    // Defines a style button for the open button
    //
    const StyledButtonOpen = styled(Button)({
        display: 'none',
        '@media screen and (max-width: 1024px)': {
            display: 'inline-flex',
            position: 'absolute',
            right: 0,
            minWidth: '54px',
            height: '40px',
            borderRadius: '15px 0 15px 0',
            margin: '0 36px 0 0',
        }
    })
    //
    // Defines a style button for the close button
    //
    const StyledButtonClose = styled(Button)({
        display: 'none',
        '@media screen and (max-width: 1024px)': {
            display: 'inline-flex',
            minWidth: '170px',
            height: '40px',
            borderRadius: '12px 0 12px 0',
            margin: '0 7px 2px 0',
            textTransform: 'inherit',
        }
    });
    //
    // Identify the active result.
    //
    const searchResult = props.searchResult || {}
    const result = searchResult[search.getActiveSource()]?.result || {}
    const searchResultCount = new Intl.NumberFormat(Constants.locale[search.getLanguage()]).format(result.total || 0)
    useEffect(() => {
        if (searchResultCount === '0') {
            handleSidebarClose()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResultCount]);
    useEffect(() => {
        if (window.innerWidth > 820 && search.getActiveSource() === Constants.resultSource.lucidworks) {
            handleSidebarClose()
            props.setStateActiveSidebar(false)
            setSidebarStyles(false)
            document.body.classList.remove('body-no-scroll');
          }
        //
        // Keep prev searchResultCount
        //
        props.setStatePrevTotalResult(searchResultCount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, searchResultCount]);
    const searchResultCountXref = searchResult[Constants.resultSource.xRef] || 0
    const showLoadingOrXref = searchResultCountXref.result && (searchResultCountXref.result.total !== 0)
    const isLoading = ((searchResultCount === '0') && props.searchParameters[Constants.parameter.didYouMean] !== Constants.didYouMean.noSuggestionsAvailable)
    const activeSource = props.searchParameters[Constants.parameter.activeSource]
    useEffect(() => {
        if (!props.stateActiveSidebar){
            handleSidebarClose()
        }
        if ((search.getActiveSource() === Constants.resultSource.xRef)) {
            window.digitalData.page.pageInfo.pageName = 'search:cross-reference'
        }else {
            window.digitalData.page.pageInfo.pageName = 'search'
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSource]);
    const facets = searchResult[search.getActiveSource()]?.result?.facets || {}
    const facetNames = Object.keys(facets)
    const hasFiltersLucidWorks = (filter.getFilterNames(Constants.resultSource.lucidworks).filter(filterName => ((facets[filterName] || []).length > 0)).length > 0)
    const hasXrefResults = searchResult[Constants.resultSource.xRef]?.result?.total > 0
    var activeFilters = []
    filter.getActiveFilters().forEach(filterName => {
        if (
               facetNames.includes(filterName)
            && filter.isDisplayEnabled(filterName)
            && (filter.getActiveValues(filterName).length > 0)
        ) {
            activeFilters.push(filterName)
        }
    })
    //
    // Render the component.
    //
    return (
        <div className={ isLoading ? 'loading-all' : ''}>
            {(search.getActiveSource() === Constants.resultSource.xRef) &&
                <div className='BottomMenuSTabletXref'>
                    <BottomMenu
                        filter={filter}
                        handleSidebarOpen={handleSidebarOpen}
                        isXref={true}
                        search={search}
                        searchResult={props.searchResult}
                        searchParameters={props.searchParameters}
                        setSearchParameters={props.setSearchParameters}
                    />
                </div>
            }
            <div className={(search.getActiveSource() === Constants.resultSource.xRef) ? 'SearchResults TabletResolution' : 'SearchResults'}>
                <div className={`Overlay ${(sidebarOpen || sidebarStyles) ?
                    search.getActiveSource() === Constants.resultSource.lucidworks ? 'overlay-openLucid' :
                    search.getActiveSource() === Constants.resultSource.xRef ? 'overlay-openXref' :
                    'overlay-open'
                    : ''}`}
                    onClick={handleSidebarClose}>
                </div>
                {(search.getActiveSource() === Constants.resultSource.lucidworks) &&
                    <div className='BottomMenuSTablet'>
                        <div className='backArrowContainerSTablet'>
                            <span
                                className='backArrowHeader'
                                onClick={search.handleBackClick}
                                style={((searchResultCount === '0' || !hasFiltersLucidWorks) && isLoading) ? { pointerEvents: 'none' } : {}}
                            >
                                <ArrowBack
                                    sx={{
                                        width: '24px',
                                        height: '24px',
                                        alignSelf: 'center',
                                        color: ((searchResultCount === '0' || !hasFiltersLucidWorks) && isLoading) ? 'rgba(0, 0, 0, 0.26)': '#03234b',
                                    }}
                                />
                                <span style={((searchResultCount === '0' || !hasFiltersLucidWorks) && isLoading) ? { color: 'rgba(0, 0, 0, 0.26)' } : {}} className='BackArrowText'> {search.getLabel(Labels.SearchBox.BackArrow)} </span>
                            </span>
                        </div>
                        <XrefNotice
                            config={props.config}
                            searchParameters={props.searchParameters}
                            searchResult={props.searchResult}
                            setSearchParameters={props.setSearchParameters}
                            isLoading={isLoading}
                            isSmallTablet={true}
                        />
                        <StyledButtonOpen variant='contained' color='primary' onClick={handleSidebarOpen} disabled={searchResultCount === '0' || !hasFiltersLucidWorks}>
                            {
                                activeFilters?.length > 0 ?
                                    <div className='filterContainer'>
                                        <div className="notifyCircle"></div>
                                        <RiFilter2Fill style={{  width: '24px', height: '24px', color: 'white'}}/>
                                    </div>
                                :
                                    <RiFilter2Line style={{  width: '24px', height: '24px', color: 'white'}}/>
                            }
                        </StyledButtonOpen>
                    </div>
                }
                <div className={
                    (search.getActiveSource() === Constants.resultSource.xRef) ? (`FilterPanelXref Sidebar ${sidebarOpen ? 'open' : sidebarStyles ? 'closing' : 'closed'}`)
                    : (search.getActiveSource() === Constants.resultSource.lucidworks) ? (`FilterPanel SidebarSTablet ${sidebarOpen ? 'open' : sidebarStyles ? 'closing' : 'closed'} ${ (hasFiltersLucidWorks || isLoading || hasXrefResults) ? '' : 'NoFilters'}`)
                    : 'FilterPanel'
                    }>
                    <div className='TopPart'>
                        <div className='CloseButton'>
                            <IconButton
                                color='secondary'
                                onClick={handleSidebarClose}
                            ><ClearIcon sx={{fontSize: '1.8rem'}}/></IconButton>
                        </div>
                        {
                            (
                                   (props.searchParameters[Constants.parameter.didYouMean] !== Constants.didYouMean.noSuggestionsAvailable)
                                || (props.config.searchResults.preserveFilters)
                                || (search.getActiveSource() === Constants.resultSource.xRef)
                            ) &&
                            <ActiveFilters
                                config={props.config}
                                cachedFilters={props.cachedFilters}
                                searchParameters={props.searchParameters}
                                // This is using the filter result which may preserve the filters as long
                                // as query text has not changed, depending on the client configuration.
                                searchResult={props.filterResult}
                                setCachedFilters={props.setCachedFilters}
                                setFilterResult={props.setFilterResult}
                                setSearchParameters={props.setSearchParameters}
                                setSearchResult={props.setSearchResult}
                                setpreviousFacets={props.setpreviousFacets}
                                sidebarOpen={sidebarOpen}
                                sidebarStyles={sidebarStyles}
                                isLoading={isLoading}
                            />
                        }
                    </div>
                    <SourceFilter
                        config={props.config}
                        searchParameters={props.searchParameters}
                        searchResult={props.searchResult}
                        setSearchParameters={props.setSearchParameters}
                        setSearchResult={props.setSearchResult}
                        setpreviousFacets={props.setpreviousFacets}
                    />
                    {isLoading && (
                        <div>
                            <LoadingTreeListFilter
                                config={props.config}
                                searchParameters={props.searchParameters}
                                previousFacets={props.previousFacets}
                            />
                            <LoadingCheckboxListFilter
                                config={props.config}
                                docFilterLoading={props.docFilterLoading}
                                searchParameters={props.searchParameters}
                            />
                            {!showLoadingOrXref && (
                                <LoadingXrefNotice
                                    searchParameters={props.searchParameters}
                                />
                            )}
                        </div>
                    )}
                    {!isLoading && <FilterPanel
                        config={props.config}
                        cachedFilters={props.cachedFilters}
                        cacheNoResult={props.cacheNoResult}
                        searchParameters={props.searchParameters}
                        // This is using the filter result which may preserve the filters as long
                        // as query text has not changed, depending on the client configuration.
                        searchResult={props.filterResult}
                        setCachedFilters={props.setCachedFilters}
                        setCacheNoResultState={props.setCacheNoResultState}
                        setDocFilterLoadingState={props.setDocFilterLoadingState}
                        setFilterResult={props.setFilterResult}
                        setSearchParameters={props.setSearchParameters}
                        setSearchResult={props.setSearchResult}
                        setpreviousFacets={props.setpreviousFacets}
                        setLoadingState={props.setLoadingState}
                    />}
                    {(showLoadingOrXref && !sidebarStyles) && (
                        <XrefNotice
                            config={props.config}
                            searchParameters={props.searchParameters}
                            searchResult={props.searchResult}
                            setSearchParameters={props.setSearchParameters}
                            isLoading={isLoading}
                        />
                    )}
                    <div className='BottomMenu'>
                        <div className='BottomMenuContent'>
                            <StyledButtonClose variant='contained' color='primary' onClick={handleSidebarClose}>
                                {`${search.getLabel(Labels.CheckboxListFilter.ShowResults())} (${props.resultXref || 0})`}
                            </StyledButtonClose>
                        </div>
                        <div className='BottomMenuContentSTablet'>
                            <StyledButtonClose variant='contained' color='primary' onClick={handleSidebarClose}>
                                {`${search.getLabel(Labels.CheckboxListFilter.ShowResults())} (${searchResultCount || 0})`}
                            </StyledButtonClose>
                        </div>
                    </div>
                </div>
                {
                    (search.getActiveSource() === Constants.resultSource.xRef) ?
                        <SearchResultsTable
                            searchParameters={props.searchParameters}
                            searchResult={props.searchResult}
                            setSearchParameters={props.setSearchParameters}
                            setSearchResult={props.setSearchResult}
                            setpreviousFacets={props.setpreviousFacets}
                            handleSidebarOpen={handleSidebarOpen}
                            resultXref={props.resultXref}
                            setResultXrefState={props.setResultXrefState}
                            filter={filter}
                            config={props.config}
                            isFirstClickXRef={props.isFirstClickXRef}
                            setIsFirstClickXRef={props.setIsFirstClickXRef}
                        />
                    :
                        <div className={`SearchResultsList ${(hasFiltersLucidWorks || isLoading) ? '' : 'NoFiltersResults'} ${isLoading ? 'Loading' : ''}`}>
                            <Stats
                                cachedFilters={props.cachedFilters}
                                cacheNoResult={props.cacheNoResult}
                                config={props.config}
                                filter={filter}
                                noResult={props.noResult}
                                searchParameters={props.searchParameters}
                                searchResult={props.searchResult}
                                setCachedFilters={props.setCachedFilters}
                                setCacheNoResultState={props.setCacheNoResultState}
                                setNoResultState={props.setNoResultState}
                                setSearchParameters={props.setSearchParameters}
                                setSearchResult={props.setSearchResult}
                                setpreviousFacets={props.setpreviousFacets}
                            />
                            <SearchResultsList
                                config={props.config}
                                filterResult={props.filterResult}
                                searchParameters={props.searchParameters}
                                searchResult={props.searchResult}
                                setCacheNoResultState={props.setCacheNoResultState}
                                setFilterResult={props.setFilterResult}
                                setSearchParameters={props.setSearchParameters}
                                setSearchResult={props.setSearchResult}
                                setpreviousFacets={props.setpreviousFacets}
                                isFirstClick={props.isFirstClick}
                                setIsFirstClick={props.setIsFirstClick}
                            />
                        </div>
                }
                {/* <div className='SponsoredLinks'>
                    <SponsoredLinks
                        config={this.props.config}
                        searchParameters={this.props.searchParameters}
                        searchResult={this.props.searchResult}
                        source={Constants.defaultParameterValue[Constants.parameter.activeSource]}
                    />
                </div> */}
                <div className={`${(search.getActiveSource() === Constants.resultSource.xRef) ? 'thirdColumnTableXref' : (isLoading) ? 'thirdColumnLoading' : 'thirdColumn'}`}>
                </div>
            </div>
            {
                (search.getActiveSource() !== Constants.resultSource.xRef) &&
                    <div>
                        <Feedback
                            config={props.config}
                            searchParameters={props.searchParameters}
                        />
                    </div>
            }
        </div>
    )
}

SearchResults.propTypes = {
    cachedFilters: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    docFilterLoading: PropTypes.object.isRequired,
    noResult: PropTypes.bool.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setCachedFilters: PropTypes.func.isRequired,
    setCacheNoResultState: PropTypes.func.isRequired,
    setDocFilterLoadingState: PropTypes.func.isRequired,
    setFilterResult: PropTypes.func.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
    setpreviousFacets: PropTypes.func.isRequired,
    setStateActiveSidebar: PropTypes.func.isRequired,
    setStatePrevTotalResult: PropTypes.func.isRequired,
    stateActiveSidebar: PropTypes.bool.isRequired,
}

export default SearchResults