//
// React.
//
import PropTypes from 'prop-types'
//
// Search.
//
import CheckboxListFilter from '../Filters/CheckboxListFilter/CheckboxListFilter'
import Constants from '../../config/Constants'
import FieldTreeFilter from '../Filters/FieldTreeFilter/FieldTreeFilter'
import ListFilter from '../Filters/ListFilter/ListFilter'
import Search from '../../actions/Search'
import TreeListFilter from '../Filters/TreeListFilter/TreeListFilter'
import Util from '../../services/Util'
//
// CSS.
//
import './FilterPanel.css'

class FilterPanel extends Search {
    //
    // Construct a new instance.
    //
    constructor(props) {
        super(props);
        this.runTrackingCalled = false;
    }
    //
    // Function that allows each filter to be concatenated into a list
    //
    concatenateFilters = (navigationFilters) => {
        const concatenated = navigationFilters.map((filter) => {
            if (Array.isArray(filter)) {
                return filter.map((subFilter) => subFilter.split(Constants.categorySeparator).join('|')).join('|');
            } else {
                return filter.split(Constants.categorySeparator).join('|');
            }
        }).join('|');
        return concatenated.replace(/\|+/g, '|').replace(/^\|/, '').replace(/\|$/, '');
    }
    //
    // Function that allows to calculate the level of the filter that is applied in the navigation filters
    //
    countFiltersInData = (navigationFilters) => {
        if (navigationFilters.length === 0) {
            return 0;
        }
        const combinedFilters = navigationFilters.join(Constants.categorySeparator);
        const filters = combinedFilters.split(Constants.categorySeparator);
        const uniqueFilters = filters.filter((filter, index, self) => {
            return ((filter.trim() !== '') && (self.indexOf(filter) === index));
        });
        return uniqueFilters.length;
    }
    //
    // Function that allows to track filters applied by the user
    //
    runTracking(searchParameters,filters) {
        //
        // Variable used to verify if a navigation filter was tracked, if so, do not track the filters checkbox.
        //
        var navigationFilterTracked = false;
        //
        // Formatting filters (Navigation filters)
        //
        const navigationFilters = Object.keys(searchParameters)
            .filter(filterName => this.isTreeListFilter(filterName))
            .map(key => {
                const labelEn = filters[key]?.label?.en || filters[key]?.label || key;
                const values = Array.isArray(searchParameters[key]) ? searchParameters[key] : [searchParameters[key]];
                return values.map(value => `${labelEn}:${value}`).join('|');
            });
        //
        // As there are different types of navigation filters,
        // when one is used and the list is deleted (remove the filter),
        // it remains but empty ([]), then it must be checked that the filter list has values ​​so it is
        // detected that it is a filter applied by the user that must be tracked.
        //
        if (navigationFilters?.length > 0 && (navigationFilters[0]?.length !== 0 || navigationFilters[1]?.length !== 0)) {
            if ((this.concatenateFilters(navigationFilters) !== '')
                && (window?.digitalData?.search?.searchNavigationFilters !== this.concatenateFilters(navigationFilters))) {
                window.digitalData.search = {
                    searchEvent: 'searchNavigationFilter',
                    searchNavigationFilters: this.concatenateFilters(navigationFilters),
                    searchNavigationFiltersDepth: this.countFiltersInData(navigationFilters),
                }
                if (window._satellite) {
                    window._satellite.track('searchNavigationFilters')
                    navigationFilterTracked = true;
                }
            }
        }
        //
        // Formatting filters (Checkbox filters)
        //
        const CheckboxFilters = Object.keys(searchParameters)
            .filter(filterName => this.isCheckBoxListFilter(filterName))
            .map(key => {
                const labelEn = filters[key]?.label?.en || filters[key]?.label || key;
                const values = Array.isArray(searchParameters[key]) ? searchParameters[key] : [searchParameters[key]];
                return values.map(value => `${labelEn}:${value}`).join('|');
            });
        //
        // As there are different types of CheckboxFilters filters,
        // when one is used and the list is deleted (remove the filter),
        // it remains but empty ([]), then it must be checked that the filter list has values ​​so it is
        // detected that it is a filter applied by the user that must be tracked.
        //
        if ((CheckboxFilters?.length > 0) && ((CheckboxFilters[0]?.length !== 0) || (CheckboxFilters[1]?.length !== 0))) {
            const nonEmptyFilters = CheckboxFilters.filter(filter => (filter !== ''))
            const concatenateFilters = nonEmptyFilters.join('|')
            if (concatenateFilters !== '' && !navigationFilterTracked) {
                window.digitalData.search = {
                    searchEvent: 'searchCheckboxFilter',
                    searchCheckboxFilters: concatenateFilters,
                }
                if (window._satellite) {
                    window._satellite.track('searchCheckboxFilters')
                }
            }
        }
    }

    render = () => {
        //
        // Get configuration settings.
        //
        const filters = this.props.config.filters || []
        //
        // Get search result data for the current source.
        //
        const searchResult = this.props.searchResult || {}
        const facets = searchResult[this.getActiveSource()]?.result?.facets || {}
        const hits = searchResult[this.getActiveSource()]?.result?.hits || {}

        if (!this.runTrackingCalled) {
            this.runTracking(this.props.searchParameters, filters);
            this.runTrackingCalled = true;
        }
        //
        // Render the filter panel.
        //
        return (
            <div className='FiltersContainer'>
                {
                    //
                    // Iterate over the configured filters to preserve their order.
                    //
                    Object.keys(filters).map((filterName) => {
                        //
                        // Do not display a filter which is not configured for the active result source.
                        //
                        const displaySources = filters[filterName].display?.sources || []
                        if (!displaySources.includes(this.getActiveSource())) {
                            return null
                        }
                        //
                        // Do not display a filter without corresponding facet result. A field tree filter
                        // will use different facets which need to be tested individually.
                        //
                        const filter = filters[filterName] || {}
                        const filterType = filter.type || ''
                        if (filterType === Constants.filterType.fieldtree) {
                            if (Util.intersection(filter.fields || [], Object.keys(facets)).length === 0) {
                                console.error(`The facets needed for rendering the '${filterName}' filter are missing: "${filter.fields.join('", "')}"`)
                                return null
                            }
                        }
                        else if (!facets[filterName]) {
                            return null
                        }
                        //
                        // Display a filter depending on its configured type.
                        //
                        if (filterType === Constants.filterType.checkboxlist) {
                            return (
                                <CheckboxListFilter
                                    key={filterName}
                                    name={filterName}
                                    cachedFilters={this.props.cachedFilters}
                                    cacheNoResult={this.props.cacheNoResult}
                                    config={this.props.config}
                                    searchParameters={this.props.searchParameters}
                                    searchResult={this.props.searchResult}
                                    setCachedFilters={this.props.setCachedFilters}
                                    setCacheNoResultState={this.props.setCacheNoResultState}
                                    setDocFilterLoadingState={this.props.setDocFilterLoadingState}
                                    setFilterResult={this.props.setFilterResult}
                                    setSearchParameters={this.props.setSearchParameters}
                                    setSearchResult={this.props.setSearchResult}
                                    setpreviousFacets={this.props.setpreviousFacets}
                                    setLoadingState={this.props.setLoadingState}
                                    xrefHits={hits}
                                />
                            )
                        }
                        if (filterType === Constants.filterType.fieldtree) {
                            return (
                                <FieldTreeFilter
                                    key={filterName}
                                    name={filterName}
                                    cacheNoResult={this.props.cacheNoResult}
                                    config={this.props.config}
                                    searchParameters={this.props.searchParameters}
                                    searchResult={this.props.searchResult}
                                    setCacheNoResultState={this.props.setCacheNoResultState}
                                    setDocFilterLoadingState={this.props.setDocFilterLoadingState}
                                    setFilterResult={this.props.setFilterResult}
                                    setLoadingState={this.props.setLoadingState}
                                    setSearchParameters={this.props.setSearchParameters}
                                    setSearchResult={this.props.setSearchResult}
                                    setpreviousFacets={this.props.setpreviousFacets}
                                />
                            )
                        }
                        if (filterType === Constants.filterType.list) {
                            return (
                                <ListFilter
                                    key={filterName}
                                    name={filterName}
                                    cacheNoResult={this.props.cacheNoResult}
                                    config={this.props.config}
                                    searchParameters={this.props.searchParameters}
                                    searchResult={this.props.searchResult}
                                    setCacheNoResultState={this.props.setCacheNoResultState}
                                    setFilterResult={this.props.setFilterResult}
                                    setSearchParameters={this.props.setSearchParameters}
                                    setSearchResult={this.props.setSearchResult}
                                    setpreviousFacets={this.props.setpreviousFacets}
                                />
                            )
                        }
                        if (filterType === Constants.filterType.treelist) {
                            return (
                                <TreeListFilter
                                    key={filterName}
                                    name={filterName}
                                    cachedFilters={this.props.cachedFilters}
                                    cacheNoResult={this.props.cacheNoResult}
                                    config={this.props.config}
                                    searchParameters={this.props.searchParameters}
                                    searchResult={this.props.searchResult}
                                    setCachedFilters={this.props.setCachedFilters}
                                    setCacheNoResultState={this.props.setCacheNoResultState}
                                    setDocFilterLoadingState={this.props.setDocFilterLoadingState}
                                    setFilterResult={this.props.setFilterResult}
                                    setLoadingState={this.props.setLoadingState}
                                    setSearchParameters={this.props.setSearchParameters}
                                    setSearchResult={this.props.setSearchResult}
                                    setpreviousFacets={this.props.setpreviousFacets}
                                />
                            )
                        }
                        return (
                            <div
                                key={filterName}
                            >
                                Unknown filter type '{filterType}' for facet '{filterName}'. Did you <b>restart the middleware</b> after a configuration change?
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

FilterPanel.propTypes = {
    config: PropTypes.object.isRequired,
    cachedFilters: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setCachedFilters: PropTypes.func.isRequired,
    setFilterResult: PropTypes.func.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
    setpreviousFacets: PropTypes.func.isRequired,
}

export default FilterPanel