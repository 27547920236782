//
// React.
//
import PropTypes from 'prop-types'
import React, { useState }  from 'react';
//
// Material UI.
//
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { RiLoaderLine } from 'react-icons/ri'
import { ArrowBack} from '@mui/icons-material'
//
// Search.
//
import Constants from '../../../../config/Constants'
import Util from '../../../../services/Util'
//
// Data used to populate a filter in "loading" state.
//
const loadingStateData = [
    { key: '1', value: '-- -- --'},
    { key: '2', value: '-- -- --'},
    { key: '3', value: '-- -- --'},
    { key: '4', value: '-- -- --'},
]
//
// Get the selected tree segment (i.e. the last one) from the search
// parameters.
//
const getParameterValue = (searchParameters, filterName) => {
    //
    // Make sure a search parameter exists for the given filter name.
    // Search parameters are always arrays. For tree filters, only one
    // entry is used.
    //
    const searchParameter = searchParameters[filterName] || []
    if (searchParameter.length === 0) {
        return null
    }
    //
    // Split the parameter value at the segment separator, omitting empty
    // segments.
    //
    const segments = searchParameter[0].split(Constants.categorySeparator).filter(part => (part.trim() !== ''))
    return (segments.length > 0) ? segments[segments.length - 1] : null
}
//
// Component logic.
//
const LoadingTreeListFilter = (props) => {
    //
    // Setting the previous facets in a state here to use the render cycle of this component.
    // This prevents unexpected changes to the dynamic loading screen.
    //
    const [facetsToLoad] = useState(props?.previousFacets || {})
    //
    // Get the configured filters.
    //
    const configFilters = props.config?.filters || {}
    //
    // Filter keys containing "Tree"
    //
    const treeKeys = Object.keys(facetsToLoad).filter(key => key.includes("Tree"));
    //
    // Check if all the arrays corresponding to those keys are empty
    //
    const allTreeKeysEmpty = treeKeys.every(key => Array.isArray(facetsToLoad[key]) && facetsToLoad[key].length === 0);
    //
    // Iterate over the keys in the filters object
    // If the corresponding key in previousFacets exists and its array is empty
    // Delete the filter from the filters object
    //
    const filters = allTreeKeysEmpty
        ? configFilters
        : Object.keys(configFilters).reduce((acc, key) => {
            if (facetsToLoad[key] && facetsToLoad[key].length > 0) {
                acc[key] = configFilters[key];
            }
            return acc;
        }, {});
    const language = props.searchParameters[Constants.parameter.language] || Constants.defaultParameterValue[Constants.parameter.language]
    //
    // Render the component.
    //
    return (
        <div className='TreeListFilter'>
            {
                //
                // Display "loading" status for all tree list filters.
                //
                Object.keys(filters)
                    .filter(filterName => (filters[filterName].type === Constants.filterType.treelist))
                    .map(filterName => {
                        const parameterValue = getParameterValue(props.searchParameters, filterName)
                        return <Box
                                key={filterName}
                                sx={{
                                    borderTop: 1,
                                    borderTopColor: '#dbdee2',
                                    borderBottom: 1,
                                    borderBottomColor: '#dbdee2',
                                    borderLeft: 4,
                                    borderLeftColor: filters[filterName].display.borderColor,
                                    paddingLeft: '0.5rem',
                                    mb: 2,
                                }}
                            >
                                <Stack className='stackSpace' direction='row' alignItems='center'>
                                    <Typography variant='overline' style={{ display: 'flex', alignItems: 'center'}}>
                                        {
                                            //
                                            // If a parameter value exists, display it including the back arrow.
                                            // Otherwise, just show the filter label.
                                            //
                                            parameterValue
                                                ? <><IconButton sx={{ '&:hover': { backgroundColor: 'transparent' } }}><ArrowBack /></IconButton>{ Util.getLabel(parameterValue) }</>
                                                : Util.getLabel(filters[filterName].label, language)
                                        }
                                    </Typography>
                                </Stack>
                                {
                                    loadingStateData.map(entry => (
                                        <Stack
                                            key={entry.key}
                                            sx={{ height: '2rem' }}
                                            direction='row'
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <span
                                                className='ListEntry'
                                            >
                                                <Typography>{`${entry.value}`}</Typography>
                                            </span>
                                            <IconButton
                                                size='small'
                                            >
                                                <RiLoaderLine style={{ width: '20px', height: '20px', transform: 'rotate(90deg)' }} color="#767c84" />
                                            </IconButton>
                                        </Stack>
                                    ))
                                }
                            </Box>
                        }
                    )
            }
        </div>
    );
};

LoadingTreeListFilter.propTypes = {
    config: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
}

export default LoadingTreeListFilter;
