//
// React.
//
import React from 'react'
import PropTypes from 'prop-types'
//
// Material UI.
//
import { Checkbox, FormControlLabel, FormGroup, Typography, Stack} from '@mui/material'
//
// Search.
//
import Constants from '../../../config/Constants'
import Filter from '../../../actions/Filter'
import Util from '../../../services/Util'
//
// CSS.
//
import './CheckboxListFilter.css'

class CheckboxListFilter extends Filter {
    //
    // Construct a new instance.
    //
    constructor(props) {
        super(props);
        this.state = {
          displayedEntries: this.props?.config?.filterOptions || 10,
        };
        this.showMoreRef = React.createRef()
        this.lastIndexApplied = 0
    }
    //
    // Function to load more entries
    //
    loadMoreEntries = (totalFilters) => {
        this.setState({
          displayedEntries: totalFilters || 10,
        });
    };
    //
    // Function to Show less entries
    //
    showLessEntries = () => {
        if(this.lastIndexApplied > 0){
            this.setState({
                displayedEntries: this.lastIndexApplied,
            });
        } else {
            this.setState({
                displayedEntries: this.props?.config?.filterOptions || 10,
            });
        }
    };
    //
    // Used to check if there are selected options for a specific filter category
    // and if any of them is beyond the displayedEntries limit.
    // If so, trigger the click event on showmoreRef to show all the filters.
    //
    componentDidMount() {
        const filterName = this.props.name
        const selectedOptions = this.props.searchParameters[filterName] || []
        const filterValues = this.props?.cachedFilters[filterName]?.filterValues || [];
        let maxIndex = 0
        let expand = false;
        selectedOptions.forEach(option => {
            const indexInValues = filterValues.findIndex(value => value.value === option);
            if ((indexInValues + 1) > this.state.displayedEntries) {
                maxIndex = Math.max(maxIndex, indexInValues + 1);
                expand = true;
            }
        });
        if (this.showMoreRef.current && selectedOptions.length > 0 && expand) {
            this.lastIndexApplied = maxIndex;
            this.setState({
                displayedEntries: maxIndex,
            });
            // this.showMoreRef.current.click();
        }
    }
    //
    // Render the component.
    //
    render = () => {
        //
        // Get the filter configuration.
        //
        const filterName = this.props.name
        const filter = this.getConfiguredFilter(filterName)
        //
        // Do not continue, if there's nothing to show.
        //
        const filterValues = this.getValues(filterName)
        if (filterValues.length === 0) {
            return null
        }
        //
        // Retrieve selected values.
        //
        const selectedOptions = this.props.searchParameters[filterName] || []
        const cachedFilter = this.props.cachedFilters[filterName] || {}
        const searchEnabled = (typeof(filter.searchEnabled) !== 'undefined') ? filter.searchEnabled : true
        //
        // Determine if an other filter has changed and select the active
        // search parameters accordingly (cannot use the cached parameters
        // if an other filter has changed, because the facet entries are
        // not valid any longer).
        //
        const cachedSearchParameters = cachedFilter.searchParameters || {}
        const searchParameters = this.props.searchParameters || {}
        const otherFilterHasChanged = this.getActiveFilters().filter(activeFilterName => (activeFilterName !== filterName)).reduce((changeDetected, activeFilterName) => {
            //
            // Detect a change only on other filters computing the symmetrical
            // difference - remember: Filter values are always arrays.
            //
            return changeDetected || (Util.symmetricalDifference(cachedSearchParameters[activeFilterName], searchParameters[activeFilterName]).length > 0)
        }, false)
        const activeSearchParameters = otherFilterHasChanged ? searchParameters : cachedSearchParameters
        //
        // Choose the active filter values: Do not use the cached version
        // if it doesn't exist yet (obviously) or if an other filter has
        // changed.
        //
        const filterSearchParameters = searchParameters[filterName] || []
        let activeFilterValues = cachedFilter.filterValues || []
        if (!cachedFilter.filterValues || otherFilterHasChanged || (filterSearchParameters.length === 0)) {
            activeFilterValues = this.getValues(filterName)
        }
        //
        // Filter the activeFilterValues to display only the desired number of entries
        //
        const entriesToDisplay = activeFilterValues.slice(0, this.state.displayedEntries);
        //
        // Update the value of xRefReplacement filters if filters have been selected by the user.
        //
        if ((filterName === Constants.xRefFilter.replacement) || (filterName === Constants.xRefFilter.manufacturer)) {
            const filters = [
                { parameter: Constants.xRefFilter.manufacturer, field: Constants.xRefColumn.vendorName },
                { parameter: Constants.xRefFilter.replacement, field: Constants.xRefColumn.replacement },
            ];
            const otherFilterCategory = (filterName === Constants.xRefFilter.replacement) ? Constants.xRefFilter.manufacturer : Constants.xRefFilter.replacement
            const filteredResultItem = filters.reduce((filtered, filter) => {
                if (filter.parameter === otherFilterCategory) {
                    const activeValues = searchParameters[filter.parameter] || []
                    if (activeValues.length > 0) {
                        return filtered.filter(row => activeValues.includes(row[filter.field]))
                    }
                }
                return filtered;
            }, this.props.xrefHits)
            activeFilterValues.forEach(item => {
                const replacementValue = item.value
                const fieldToMatch = (filterName === Constants.xRefFilter.replacement) ? Constants.xRefColumn.replacement : Constants.xRefColumn.vendorName
                const count = filteredResultItem.filter(obj => (obj[fieldToMatch] === replacementValue)).length
                item.count = count
            });
        }
        //
        // Render the checkbox filter.
        //
        return (
            <div className={(this.getActiveSource() === Constants.resultSource.xRef) ? 'CheckboxListFilterXref' : 'CheckboxListFilter'}>
                {activeFilterValues.length > 0 && (
                    <Stack className='stackSpace' direction='row' alignItems='center'><Typography variant='overline'>{this.getLabel(filter?.label || 'No label')}</Typography></Stack>
                )}
                <FormGroup className='formGroupFilter'>
                {
                    //
                    // Display all values from the search result facet.
                    //
                    entriesToDisplay.map(option =>
                        <FormControlLabel
                            key={ option.value }
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#eeeff1',
                                },
                                color: '#525a63',
                            }}
                            control={
                                <Checkbox
                                    checked={ selectedOptions.includes(option.value) }
                                    disabled={ option.count === 0}
                                    onClick={
                                        (event) => {
                                            //
                                            // Tracking xref Filters
                                            //
                                            setTimeout(() => {
                                                if (this.getActiveSource() === Constants.resultSource.xRef) {
                                                    const CheckboxFilters = Object.keys(this.props.searchParameters)
                                                    .filter(key => key.includes(Constants.xRefFilter.manufacturer) || key.includes(Constants.xRefFilter.replacement))
                                                    .map(key => {
                                                        const labelEn = this.props.config.filters[key]?.label?.en || this.props.config.filters[key]?.label || key;
                                                        const values = Array.isArray(this.props.searchParameters[key]) ? this.props.searchParameters[key] : [this.props.searchParameters[key]];
                                                        return values.map(value => `${labelEn}:${value}`).join('|');
                                                    });
                                                      if (CheckboxFilters?.length > 0 && (CheckboxFilters[0]?.length !== 0 || CheckboxFilters[1]?.length !== 0)) {
                                                        const nonEmptyFilters = CheckboxFilters.filter(filter => filter !== '');
                                                        const concatenateFilters = nonEmptyFilters.join('|');
                                                        if (concatenateFilters !== '') {
                                                            window.digitalData.search = {
                                                                searchEvent: 'searchCheckboxFilter',
                                                                searchCheckboxFilters: concatenateFilters,
                                                            }
                                                            if (window._satellite) {
                                                                window._satellite.track('searchCheckboxFilters')
                                                            }
                                                        }
                                                    }
                                                }
                                            }, 100);
                                        }
                                    }
                                    sx={{
                                        color: '#525a63',
                                        '&.Mui-checked': {
                                            '& + .MuiFormControlLabel-label': {
                                                color: '#03234b',
                                                fontWeight: 'bold'
                                              },
                                          color: '#3cb4e6',
                                        },
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                    onChange={
                                        (event) => {
                                            window.scrollTo(0, 0)
                                            this.props.setCacheNoResultState('')
                                            //
                                            // Compute the selected options based on the checkbox state.
                                            //
                                            const updatedSelectedOptions = event.target.checked
                                                ? selectedOptions.concat(option.value)
                                                : selectedOptions.filter(value => value !== option.value)
                                            //
                                            // Call loading page.
                                            //
                                            if (this.getActiveSource() !== Constants.resultSource.xRef) {
                                                this.props.setLoadingState(true);
                                                if (event.target.checked) {
                                                    this.props.setDocFilterLoadingState({
                                                        name: filterName,
                                                        activeFilterValues: activeFilterValues,
                                                        selectedOption: updatedSelectedOptions,
                                                    })
                                                } else {
                                                    this.props.setDocFilterLoadingState({})
                                                }
                                            }
                                            //
                                            // Update the cached search parameters and filter values. If another filter has 
                                            // changed AND there are cached filter values, clear the cache entry (so that all
                                            // categories can show up again using the facets from the search result).
                                            //
                                            const updatedCachedFilters = {
                                                ...this.props.cachedFilters,
                                                [filterName]: (otherFilterHasChanged && cachedFilter.filterValues)
                                                    ? undefined
                                                    : {
                                                        searchParameters: activeSearchParameters,
                                                        filterValues: activeFilterValues,
                                                    }
                                            }
                                            this.props.setCachedFilters(updatedCachedFilters)
                                            //
                                            // Update the search parameters and run the search.
                                            //
                                            const updatedSearchParameters = this.getUpdatedSearchParameters(filterName, updatedSelectedOptions, event.target.checked)
                                            if (searchEnabled) {
                                                const filters = this.getConfiguredFilters()
                                                var filterParameters = Object.keys(updatedSearchParameters).filter(parameter => Object.keys(filters).includes(parameter))
                                                const activeFilters = filterParameters.filter(filterName => (updatedSearchParameters[filterName] && updatedSearchParameters[filterName].length > 0))
                                                const clearFilters = (activeFilters.length === 0)
                                                this.search(updatedSearchParameters, clearFilters)
                                            } else {
                                                this.props.setSearchParameters(updatedSearchParameters)
                                                this.pushHistory(updatedSearchParameters)
                                            }
                                            //
                                            // Send signals.
                                            //
                                            const signal = (event.target.checked) ? Constants.signal.filterAdded : Constants.signal.filterRemoved
                                            this.sendSignal(signal, updatedSearchParameters, [{
                                                name: filterName,
                                                values: (event.target.checked) ? updatedSelectedOptions : option.value,
                                            }])
                                        }
                                    }
                                />
                            }
                            label={ this.getOptionLabel(option.value, activeFilterValues) }
                        />
                    )
                }
                {this.state.displayedEntries < activeFilterValues.length && (
                    <Typography variant='body1' sx={{ mt: 1, mb: 1 }}>
                        <span
                            className='Link'
                            onClick={() => this.loadMoreEntries(activeFilterValues.length)}
                            ref={this.showMoreRef}
                        >{this.getLabel(filter?.showMoreLabel || 'No label')}</span>
                    </Typography>
                )}
                 {( (this.state.displayedEntries === activeFilterValues.length) && (entriesToDisplay.length > this.props?.config?.filterOptions)) && (
                    <Typography variant='body1' sx={{ mt: 1, mb: 1 }}>
                        <span
                            className='Link'
                            onClick={this.showLessEntries}
                        >{this.getLabel(filter?.showFewerLabel || 'No label')}</span>
                    </Typography>
                )}
                </FormGroup>
            </div>
        )
    }
}

CheckboxListFilter.propTypes = {
    name: PropTypes.string.isRequired,
    cachedFilters: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setCachedFilters: PropTypes.func.isRequired,
    setCacheNoResultState: PropTypes.func.isRequired,
    setFilterResult: PropTypes.func.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
}

export default CheckboxListFilter