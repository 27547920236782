//
// React.
//
import PropTypes from 'prop-types'
import React from 'react';
//
// Material UI.
//
import { Box, Typography } from '@mui/material';
//
// Search.
//
import Labels from '../../../config/Labels'
import Util from '../../../services/Util'
import Constants from '../../../config/Constants';


const LoadingXrefNotice = (props) => {
    //
    // Value that defines the number to show when loading based on the design
    //
    const total = 39;

    const language = props.searchParameters[Constants.parameter.language] || Constants.defaultParameterValue[Constants.parameter.language];

    return (
        <div className='LoadingXrefNotice'>
            <Box className='XrefNoticeContainer' sx={{ display: 'table', border: 1, borderRadius: '6px', borderColor: '#dbdee2', backgroundColor: '#f7f8fa'}}>
                <Box className='XrefNotice' sx={{borderRadius: '6px', backgroundColor: '#fff'}}>
                    <Typography variant='overline'>{ Util.getLabel(Labels.XrefNotice.Heading, language)}</Typography>
                    <Typography variant='body2'>{ Util.getLabel(Labels.XrefNotice.Description, language)}</Typography>
                    <Typography variant='body1' sx={{ mt: 3, mb: 2.5 }}>
                        <span
                            className='Link'
                        >
                            { Util.getLabel(Labels.XrefNotice.LinkText(total), language) }
                        </span>
                    </Typography>
                </Box>
            </Box>
        </div>
    );
};

LoadingXrefNotice.propTypes = {
    searchParameters: PropTypes.object.isRequired,
}

export default LoadingXrefNotice;
