//
// React.
//
import PropTypes from 'prop-types'
//
// Components.
//
import XrefResultTable from '../ResultTables/XrefResultTable/XrefResultTable';
//
// Search.
//
import Constants from '../../config/Constants'
import Labels from '../../config/Labels'
import Search from '../../actions/Search'
//
// Material UI.
//
import { Stack, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

const SearchResultsTable = (props) => {
  //
  // Get the results for the configured source.
  //
  const search = new Search(props);
  const searchResult = props.searchResult || {}
  const result = searchResult[search.getActiveSource()]?.result || {}
  const hits = result.hits || []
  //
  // Filter the hits array to only include items that match the condition
  //
  const xrefHits = hits.filter((resultItem) => resultItem.type === Constants.resultItemType.xRef);

  return (
        <div className='SearchResultsTableXref'>
            <div style={{ width: '160px'}}>
                <span
                    className='BackFunction'
                    onClick={
                        (event) => {
                            const updatedSearchParameters = {
                                ...props.searchParameters,
                                [Constants.parameter.activeSource]: Constants.resultSource.lucidworks
                            }
                            search.pushHistory(updatedSearchParameters)
                            props.setSearchParameters(updatedSearchParameters)
                        }
                    }
                >
                    <Stack direction='row' alignItems='center'>
                        <ArrowBack
                            sx={{
                                width: '16px',
                                height: '16px',
                                margin: '0px 5px 0px -2px'
                            }}
                        />
                        <Typography
                            sx={{textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold'}}
                        >
                            { search.getLabel(Labels.SearchResultsList.BackToStResults) }
                        </Typography>
                    </Stack>
                </span>
            </div>
            <XrefResultTable
                resultItem={xrefHits}
                searchParameters={props.searchParameters}
                searchResult={props.searchResult}
                handleSidebarOpen={props.handleSidebarOpen}
                resultXref={props.resultXref}
                setResultXrefState={props.setResultXrefState}
                filter={props.filter}
                configFilters={props.config.filters}
                isFirstClickXRef={props.isFirstClickXRef}
                setIsFirstClickXRef={props.setIsFirstClickXRef}
            />
        </div>
  );

};

SearchResultsTable.propTypes = {
  searchParameters: PropTypes.object.isRequired,
  searchResult: PropTypes.object.isRequired,
  setSearchParameters: PropTypes.func.isRequired,
  setSearchResult: PropTypes.func.isRequired,
}


export default SearchResultsTable;