//
// React.
//
import PropTypes from 'prop-types'
import React from 'react'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
//
// Material UI.
//
import { Autocomplete, Stack, TextField } from '@mui/material'
import InputAdornment from "@mui/material/InputAdornment";
import { BiSearch } from 'react-icons/bi';
import { ArrowBack } from '@mui/icons-material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
//
// Search.
//
import Constants from '../../config/Constants'
import Labels from '../../config/Labels'
import Search from '../../actions/Search'
import Util from '../../services/Util'
import SystemInfo from '../SystemInfo/SystemInfo'
//
// CSS.
//
import './SearchBox.css'

class SearchBox extends Search {
    //
    // Construct a new instance.
    //
    constructor(props) {
        super(props)

        this.state = {
            suggestions: [],
            loading: false,
            isAutocompleteOpen: false,
        }
        //
        // Reference for starting the search with the IconButton.
        //
        this.searchBoxRef = React.createRef()
        //
        // Timeout ID for debouncing autocomplete.
        //
        this.timeout = null
        //
        // Get the current location
        //
        this.currentLocation = window.location.hostname
        //
        // Save the previous key used in the suggestions list
        //
        this.previousKey = '';
    }
    //
    // Get the search box (AutoComplete) input value.
    //
    getSearchBoxValue = () => {
        return this.searchBoxRef.current?.getElementsByTagName('input')[0].value || ''
    }
    //
    // Suggestions have changed, update loading state accordingly
    //
    componentDidUpdate(prevProps, prevState) {
        const searchBoxValue = this.getSearchBoxValue();
        if (searchBoxValue.length < 3) {
            if (this.state.suggestions.length > 0) {
                this.setState({
                    suggestions: [],
                    loading: false,
                });
            }
        }
    }
    handleInputChange = (newValue) => {
        const { config } = this.props;
        const minLength = config?.searchBox?.suggest?.minLength || 2;
        this.setState({ loading: newValue.length >= minLength });
    };
    //
    // Get the suggests for the given search term(s).
    //
    suggest = (searchTerms) => {
        const language = this.props.searchParameters[Constants.parameter.language] || Constants.defaultParameterValue[Constants.parameter.language];
        //
        // Send the query to the middleware and forward the response if
        // the minimum number of characters has been entered.
        //
        if (searchTerms.length < (this.props.config.searchBox?.suggest?.minLength || 2)) {
            this.setState({
                suggestions: []
            })
        } else {
            this.setState({ loading: true })
            Util.submitPostRequest(`${Constants.apiVersion}/suggest`, {
                searchTerms: searchTerms,
                [Constants.parameter.language]: language,
            }).then((json) => {
                const docs = json.response?.docs || [];
                const uniqueSuggestions = new Set(docs.map(doc => doc.value_s || doc.title_s));
                const suggestions = [...uniqueSuggestions];
                this.setState({
                    suggestions: suggestions,
                    loading: docs.length > 0 ? true : false
                });
            }).catch(error => {
                this.setState({
                    suggestions: [],
                    loading: false,
                })
                this.props.setSearchResult({
                    error: Labels.ErrorMessage.Error(error)
                })
                this.props.setpreviousFacets({})
            })
        }
    }
    handleLogoClick = () => {
        window.location.href = 'https://www.st.com/';
    };
    //
    // Verify no results
    //
    hasResults = () => {
        const searchResult = this.props.searchResult || {}
        const result = searchResult[this.getActiveSource()]?.result || {}
        const searchResultCount = new Intl.NumberFormat(Constants.locale[this.getLanguage()]).format(result.total || 0)
        const isLoading = ((searchResultCount === '0') && this.props.searchParameters[Constants.parameter.didYouMean] !== Constants.didYouMean.noSuggestionsAvailable)
        return isLoading
    }
    //
    // Render the search box.
    //
    render = () => {
        return (
            <div className='SearchBox'>
                <div className="header-logo">
                    <img src={Constants.images.stlogoHeaderNoTagLine} alt="LogoHeader" onClick={this.handleLogoClick} />
                </div>
                <Stack direction='row' className='searchContainer'>
                    <span
                        className='backArrowHeader'
                        onClick={this.handleBackClick}
                    >
                    <ArrowBack
                        sx={{
                            width: '24px',
                            height: '24px',
                            alignSelf: 'center',
                            color: '#03234b',
                        }}
                    />
                    <span className='BackArrowText'> {this.getLabel(Labels.SearchBox.BackArrow)} </span>
                    </span>
                    <Autocomplete
                        className='searchAutocomplete'
                        disabled={this.props.isloading || this.hasResults()}
                        id={Constants.parameter.queryText}
                        ref={this.searchBoxRef}
                        open={this.state.isAutocompleteOpen && this.state.loading}
                        onOpen={() => this.setState({ isAutocompleteOpen: true })}
                        onClose={() => this.setState({ isAutocompleteOpen: false })}
                        autoComplete
                        disablePortal
                        freeSolo
                        fullWidth
                        loading={this.state.loading}
                        loadingText={this.getLabel(Labels.SearchBox.Loading)}
                        options={this.state.suggestions}
                        // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
                        filterOptions={(option) => option}
                        value={
                            this.props.cacheNoResult !== ''
                                ? this.props.cacheNoResult
                                : this.props.searchParameters[Constants.parameter.queryText] || ''
                        }
                        onBlur={() => {
                            this.previousKey = '';
                        }}
                        renderInput={
                            (params) =>
                            <TextField
                                {...params}
                                placeholder={this.getLabel(Labels.SearchBox.EnterSearchTerms)}
                                variant='standard'
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <BiSearch
                                            className='SearchIcon'
                                        />
                                      </InputAdornment>
                                    ),
                                    onKeyDown: (e) => {
                                        //
                                        // Store the previous key only when the user navigates through the suggestions list
                                        //
                                        if ((e.key !== 'Enter') && (this.state.suggestions.length > 0)){
                                            this.previousKey = e.key
                                        }
                                        if (e.key === 'Enter') {
                                            if((this.props.searchParameters[Constants.parameter.queryText] !== this.getSearchBoxValue())){
                                                this.props.setpreviousFacets({})
                                            }
                                            this.startNewSearch(this.getSearchBoxValue(), '', this.previousKey === "ArrowUp" || this.previousKey === "ArrowDown" ? true : false)
                                            this.setState({ isAutocompleteOpen: false }, () => {
                                                this.searchBoxRef.current.getElementsByTagName('input')[0].value = this.props.searchParameters[Constants.parameter.queryText]
                                            });
                                            e.stopPropagation()
                                        }
                                    },
                                }}
                            />
                        }
                        // https://mui.com/material-ui/react-autocomplete/#highlights
                        renderOption={
                            (props, option, { inputValue }) => {
                                //
                                // Find the matching portions of the option.
                                //
                                const matches = match(option, inputValue, { insideWords: true })
                                const parts = parse(option, matches)
                                //
                                // Render the option, highlighting the matching portions.
                                //
                                return (
                                    <li {...props} key={option}>
                                        <div>
                                            {
                                                parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                                    >{part.text}</span>
                                                ))
                                            }
                                        </div>
                                    </li>
                                )
                            }
                        }
                        onChange={
                            (event, value, reason) => {
                                if (reason === 'clear') {
                                    //
                                    // When the input box is cleared, also clear the search term and run a search.
                                    //
                                    if (event.type === 'click' && this.props?.config?.allowEmptySearch) {
                                        this.props.setpreviousFacets({})
                                        this.startNewSearch('')
                                    }
                                } else if ((reason === 'selectOption') && (event.code !== 'Enter')) {
                                    //
                                    // When an option is selected using the mouse (i.e. not the 'Enter' key, this is
                                    // handled in the onKeyDown event), use it as the search term and run a search.
                                    //
                                    this.props.setpreviousFacets({})
                                    this.startNewSearch(value, '', true)
                                }
                            }
                        }
                        onInputChange={
                            //
                            // Update the suggestions (including debounce logic).
                            // https://stackoverflow.com/a/50276194/1552187
                            //
                            (event, value) => {
                                this.handleInputChange(value);
                                clearTimeout(this.timeout)
                                this.timeout = setTimeout(() => {
                                    this.suggest(value)
                                }, 150)
                            }
                        }
                    />
                </Stack>
                { (this.currentLocation === Constants.environments.localhost || this.currentLocation === Constants.environments.dev) &&
                    <Stack className='infoContainer'>
                        <Tooltip
                            title={
                                <React.Fragment>
                                    <SystemInfo />
                                </React.Fragment>
                            }
                            arrow
                        >
                            <InfoOutlinedIcon sx={{ padding: '5px', cursor: 'help'}} />
                        </Tooltip>
                    </Stack>
                }
            </div>
        )
    }
}

SearchBox.propTypes = {
    cachedFilters: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    filterResult: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    setCachedFilters: PropTypes.func.isRequired,
    setFilterResult: PropTypes.func.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
    setpreviousFacets: PropTypes.func.isRequired,
}

export default SearchBox