//
// React.
//
import PropTypes from 'prop-types'
//
// Material UI.
//
import { Button, Typography } from '@mui/material'
// import { Delete } from '@mui/icons-material'
//
// Search.
//
import Constants from '../../../config/Constants'
import Filter from '../../../actions/Filter'
import Labels from '../../../config/Labels'
//
// CSS.
//
import './ActiveFilters.css'

class ActiveFilters extends Filter {
    //
    // Construct a new instance.
    //
    constructor(props) {
        super(props)

        this.state = {
            tabletMobile: window.innerWidth > 1024,
        }
    }
    //
    // Add event listeners when the component is mounted
    //
    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }
    //
    // Removing event listeners when the component unmounts
    //
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }
    //
    // Handel window re-size
    //
    handleWindowResize = () => {
        if (window.innerWidth > 1024){
            this.setState({ tabletMobile: true });
        }else {
            this.setState({ tabletMobile: false });
        }
    }
    //
    // Compute the number of active Lucidworks filter options.
    //
    lengthUsedFiltersLucidResults = () => {
        var sum = 0
        this.getActiveFilters(Constants.resultSource.lucidworks).forEach(filterName => {
            const filterParameters = this.props.searchParameters[filterName] || []
            sum += filterParameters.length
        })
        return sum;
    }
    //
    // Compute the number of active XRef filter options.
    //
    lengthUsedFiltersXref = () => {
        let sum = 0;
        if (this.props.searchParameters) {
            for (const key in this.props.searchParameters) {
                if (key.includes("xRef") && Array.isArray(this.props.searchParameters[key])) {
                    const value = this.props.searchParameters[key];
                    sum += value.length;
                }
            }
        }
        return sum;
    }
    //
    // Render the component.
    //
    render = () => {
        //
        // Get the facet names from the search result data for the current source.
        //
        const searchResult = this.props.searchResult || {}
        const facets = searchResult[this.getActiveSource()]?.result?.facets || {}
        const facetNames = Object.keys(facets)
        //
        // The result contains Lucidworks facets, if at least one filter configured
        // for Lucidworks appears as a facets in the search result.
        //
        const hasLucidworksFacets = (this.getFilterNames(Constants.resultSource.lucidworks).filter(filterName => ((facets[filterName] || []).length > 0)).length > 0)
        //
        // Get the active filters depending on the active source
        // and the number of selected options.
        //
        var activeFilters = []
        this.getActiveFilters().forEach(filterName => {
            if (
                   facetNames.includes(filterName)
                && this.isDisplayEnabled(filterName)
                && (this.getActiveValues(filterName).length > 0)
            ) {
                activeFilters.push(filterName)
            }
        })
        //
        // Determine whether to hide the Typography component based on certain conditions
        //
        const shouldHide = this.props.isLoading || (!this.state.tabletMobile && activeFilters.length === 0) || this.props.searchParameters[Constants.parameter.didYouMean];
        //
        // Render the active filters.
        //
        if (
               this.props.config.searchResults.showActiveFilters
            && (activeFilters.length >= 0)
            && (
                   hasLucidworksFacets
                || (this.getActiveSource() === Constants.resultSource.xRef)
                || this.props.isLoading
            )
        ) {
            return (
                <div className='ActiveFilters'>
                    <span>{ this.getLabel(Labels.ActiveFilters.ActiveFilters) }</span>
                    {/* Commenting out the functionality of active filters in case it is required at some point */}
                    {/* {
                        //
                        // Process all active filters.
                        //
                        activeFilters.map((filterName) => {
                            const filter = this.getConfiguredFilter(filterName)
                            const searchParameterValues = this.props.searchParameters[filterName]
                            //
                            // If a filter is/was active and is visible (e.g., for the current source/tab):
                            //
                            if (searchParameterValues) {
                                const categoryLabels = this.props.config.filters[filterName].categoryLabels || {}
                                //
                                // Create a chip for each active filter value.
                                //
                                return searchParameterValues.map((searchParameterValue) => {
                                    //
                                    // Compute the value displayed in the chip. For tree list filters,
                                    // display only the last tree segment.
                                    //
                                    var displayValue = searchParameterValue
                                    if (filter.type === Constants.filterType.treelist) {
                                        //
                                        // If a parent category was selected, the value will end with a separator, thus
                                        // causing the last segment to be empty. This empty segment is not needed.
                                        //
                                        const segments = searchParameterValue.split(this.separator).filter(segment => segment !== '')
                                        displayValue = segments[segments.length - 1]
                                    }
                                    return (
                                        <Chip
                                            className='Chip'
                                            key={`${filterName}:${searchParameterValue}`}
                                            label={this.getLabel(categoryLabels[displayValue]) || displayValue}
                                            color='success'
                                            onDelete={
                                                () => {
                                                    //
                                                    // Remove clicked option from search parameters.
                                                    //
                                                    const selectedOptions = searchParameterValues.filter(value => value !== searchParameterValue)
                                                    const updatedSearchParameters = this.getUpdatedSearchParameters(filterName, selectedOptions, true)
                                                    //
                                                    // Update the search results.
                                                    //
                                                    this.search(updatedSearchParameters)
                                                    //
                                                    // Send signal.
                                                    //
                                                    this.sendSignal(Constants.signal.filterRemoved, updatedSearchParameters, [{
                                                        name: filterName,
                                                        values: [ searchParameterValue ],
                                                    }])
                                                }
                                            }
                                        />
                                    )
                                })
                            }
                            return null
                        })
                    } */}
                    {
                        <div
                            className={
                                `${
                                    (this.props.sidebarOpen || this.props.sidebarStyles)
                                        ? `ButtonContainerSideBar ${((this.getActiveSource() !== Constants.resultSource.xRef) && (this.lengthUsedFiltersLucidResults() > 0)) ? 'HasActiveFilters' : ''}`
                                        : 'ButtonContainer'
                                }`
                            }
                        >
                            <Button
                                className={
                                    `FilterButton ${
                                        (activeFilters.length > 0)
                                            ? 'FilterButtonActive'
                                            : ''
                                    } ${
                                        (this.getActiveSource() === Constants.resultSource.xRef)
                                            ? 'FilterButtonXref'
                                            : ''
                                    }`
                                }
                                variant="text"
                                disabled={activeFilters.length === 0}
                                disableRipple
                                onClick={
                                    () => {
                                        //
                                        // Create a copy of the search parameters and remove filters which are
                                        // active AND visible (e.g., for the current source/tab).
                                        //
                                        const updatedSearchParameters = {
                                            ...this.props.searchParameters,
                                            [this.getPageParameter()]: 1,
                                        }
                                        delete updatedSearchParameters[Constants.parameter.didYouMean]
                                        activeFilters.forEach(filterName => {
                                            updatedSearchParameters[filterName] = []
                                        })
                                        //
                                        // Clear the cached filters.
                                        //
                                        const updatedCachedFilters = {
                                            ...this.props.cachedFilters
                                        }
                                        activeFilters.forEach(filterName => {
                                            delete updatedCachedFilters[filterName]
                                        })
                                        this.props.setCachedFilters(updatedCachedFilters)
                                        //
                                        // The XRef search results are all in memory already. No need to search
                                        // again - instead update the search parameters only.
                                        //
                                        if (this.getActiveSource() === Constants.resultSource.xRef) {
                                            window.scrollTo(0, 0)
                                            this.props.setSearchParameters(updatedSearchParameters)
                                            this.pushHistory(updatedSearchParameters)
                                            window.digitalData.search = {
                                                searchEvent: 'searchClearFilter',
                                                searchNavigationFilters: 'no filter',
                                                searchNavigationFiltersDepth: 'no filter',
                                                searchCheckboxFilters: 'no filter',
                                            }
                                            if (window._satellite) {
                                                window._satellite.track('searchClearFilters')
                                            }
                                        }
                                        //
                                        // Update the the search results.
                                        //
                                        else {
                                            this.search(updatedSearchParameters, true)
                                            //
                                            // Send signal.
                                            //
                                            this.sendSignal(Constants.signal.filterRemoved, updatedSearchParameters, activeFilters.map(activeFilterName => ({
                                                name: activeFilterName,
                                                values: this.props.searchParameters[activeFilterName],
                                            })))
                                        }
                                    }
                                }
                            >
                                {/* <Delete /> */}
                                <Typography textTransform='none' style={{ visibility: shouldHide ? 'hidden' : 'visible'}}>
                                    {
                                        `${shouldHide ? 'Load Page' : this.getLabel(Labels.ActiveFilters.ClearAllFilters)}${
                                            (this.props.sidebarOpen || this.props.sidebarStyles) && (this.getActiveSource() === Constants.resultSource.xRef)
                                                ? '(' + this.lengthUsedFiltersXref() + ')'
                                                : (
                                                    (this.getActiveSource() === Constants.resultSource.wiki || this.getActiveSource() === Constants.resultSource.lucidworks)
                                                    && (this.props.sidebarOpen || this.props.sidebarStyles)
                                                )
                                                    ? '(' + this.lengthUsedFiltersLucidResults() + ')'
                                                    : ''
                                        }`
                                    }
                                </Typography>
                            </Button>
                        </div>
                    }
                </div>
            )
        }
        return null
    }
}

ActiveFilters.propTypes = {
    config: PropTypes.object.isRequired,
    cachedFilters: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setCachedFilters: PropTypes.func.isRequired,
    setFilterResult: PropTypes.func.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
}

export default ActiveFilters