//
// React.
//
import PropTypes from 'prop-types'
import React from 'react';
//
// Search.
//
import Labels from '../../config/Labels'
import Search from '../../actions/Search'
//
// Components.
//
import BottomMenu from '../SearchResults/BottomMenu/BottomMenu';
import Feedback from '../Feedback/Feedback'
import LoadingCheckboxListFilter from '../Filters/LoadingFilters/LoadingCheckboxListFilter/LoadingCheckboxListFilter';
import LoadingLucidworksResultItem from '../ResultItems/LoadingResultItems/LoadingLucidworksResultItem/LoadingLucidworksResultItem';
import LoadingTreeListFilter from '../Filters/LoadingFilters/LoadingTreeListFilter/LoadingTreeListFilter';
import LoadingXrefNotice from '../XrefNotice/LoadingXrefNotice/LoadingXrefNotice';
import Stats from '../Stats/Stats'
//
// Material UI.
//
import { Button, Typography } from '@mui/material'
//
// CSS.
//
import './Loading.css'

const Loading = (props) => {

    const search = new Search(props);

    return (
        <div>
            <div className='loading-all'>
                <div>
                    <div className='SearchResults'>
                        <div className='FilterPanel'>
                            <div className='ActiveFilters'>
                                <span>{ search.getLabel(Labels.ActiveFilters.ActiveFilters) }</span>
                                    <Button
                                        className='FilterButton'
                                        variant="text"
                                        disableRipple
                                        style={{ visibility: 'hidden' }}
                                    >
                                    <Typography textTransform='none'>
                                        Load Page
                                    </Typography>
                                </Button>
                            </div>
                            <div className='LeftPanel'>
                                <LoadingTreeListFilter
                                    config={props.config}
                                    searchParameters={props.searchParameters}
                                    previousFacets={props.previousFacets}
                                />
                                <LoadingCheckboxListFilter
                                    config={props.config}
                                    docFilterLoading={props.docFilterLoading}
                                    searchParameters={props.searchParameters}
                                />
                                <LoadingXrefNotice
                                    searchParameters={props.searchParameters}
                                />
                            </div>
                        </div>
                        <div className='SearchResultsList'>
                            <Stats
                                loading={true}
                                cachedFilters={props.cachedFilters}
                                searchParameters={props.searchParameters}
                                searchResult={props.searchResult}
                                setCachedFilters={props.setCachedFilters}
                            />
                            <div>
                                <LoadingLucidworksResultItem />
                            </div>
                        </div>
                        <div className="thirdColumn"></div>
                        <BottomMenu
                            search={search}
                            searchParameters={props.searchParameters}
                            searchResult={props.searchResult}
                            setSearchParameters={props.setSearchParameters}
                        />
                    </div>
                    <div>
                        <Feedback
                            config={props.config}
                            searchParameters={props.searchParameters}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Loading.propTypes = {
    cachedFilters: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    docFilterLoading: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setCachedFilters: PropTypes.func.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
}

export default Loading;