//
// React.
//
import React from 'react';

const SystemInfo = () => {
    return (
        <div>
            <h2>System Info</h2>

            <h3>Index version: 0.5</h3>
            <p>(including updated producttree from new JSON files)</p>

            <h3>Content Version: 1.0</h3>
            <p>(including xxx JSON files received from ST on 12/04/2024)</p>

            <h3>Synonym Version: 1.0</h3>
            <p>(dated xx/04/2024)</p>

            <h3>Acronym Version: 10</h3>
            <p>(dated xx/04/2024)</p>
        </div>
    );
}

export default SystemInfo;