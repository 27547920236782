//
// React.
//
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
//
// Search.
//
import ActiveFilters from '../../Filters/ActiveFilters/ActiveFilters'
import Constants from '../../../config/Constants'
import LoadingTreeListFilter from '../../Filters/LoadingFilters/LoadingTreeListFilter/LoadingTreeListFilter';
import LoadingCheckboxListFilter from '../../Filters/LoadingFilters/LoadingCheckboxListFilter/LoadingCheckboxListFilter';
import LoadingXrefNotice from '../../XrefNotice/LoadingXrefNotice/LoadingXrefNotice';
import Search from '../../../actions/Search'
import Labels from '../../../config/Labels'
import BottomMenu from '../BottomMenu/BottomMenu'
//
// CSS.
//
import '../SearchResults.css'
//
// Material UI.
//
import {IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import { Button, styled} from '@mui/material'

const Sidebar = (props) => {
    //
    // Sidebar Logic and states
    //
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const [sidebarStyles, setSidebarStyles] = useState(false);
    //
    // Function to handle sidebar logic (Close)
    //
    const handleSidebarClose = () => {
        document.body.classList.remove('body-no-scroll');
        props.setStateActiveSidebar(false)
        setSidebarOpen(false);
    };

    useEffect(() => {
        document.body.classList.add('body-no-scroll');
        //
        // Keep sidebar styles until closed
        //
        if (!sidebarOpen) {
            setTimeout(() => {
                setSidebarStyles(false);
            }, 300);
        }else {
            props.setStateActiveSidebar(sidebarOpen);
        }
        if (props.stateActiveSidebar) {
            setSidebarStyles(true)
        }
        //
        // Handle Resolution Change
        //
        const handleResolutionChange = () => {
          if (window.innerWidth > 820) {
            setSidebarStyles(false)
            document.body.classList.remove('body-no-scroll');
          }
          if (window.innerWidth <= 820 && sidebarOpen === true) {
            setSidebarStyles(true)
            document.body.classList.add('body-no-scroll');
          }
        };
        window.addEventListener('resize', handleResolutionChange);
        return () => {
          window.removeEventListener('resize', handleResolutionChange);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebarOpen]);

    const search = new Search(props);
    //
    // Defines a style button for close button
    //
    const StyledButtonClose = styled(Button)({
        display: 'none',
        '@media screen and (max-width: 1024px)': {
            display: 'inline-flex',
            minWidth: '170px',
            height: '40px',
            borderRadius: '12px 0 12px 0',
            margin: '0 7px 2px 0',
            textTransform: 'inherit',
            opacity: 0.33,
            pointerEvent: 'none',
        }
    });
    //
    // Identify the active result.
    //
    const searchResult = props.searchResult || {}
    const result = searchResult[search.getActiveSource()]?.result || {}
    const searchResultCount = new Intl.NumberFormat(Constants.locale[search.getLanguage()]).format(result.total || 0)
    const isLoading = ((searchResultCount === '0') && props.searchParameters[Constants.parameter.didYouMean] !== Constants.didYouMean.noSuggestionsAvailable)
    //
    // Render the component.
    //
    return (
        <div>
            <div className='SearchResults'>
                <div className={`Overlay ${ sidebarOpen ? (search.getActiveSource() === Constants.resultSource.lucidworks ? 'overlay-openLucid' : ''): ''}`}
                    onClick={handleSidebarClose}>
                </div>
                {(search.getActiveSource() === Constants.resultSource.lucidworks) &&
                    <BottomMenu
                        search={search}
                        searchParameters={props.searchParameters}
                        searchResult={props.searchResult}
                        setSearchParameters={props.setSearchParameters}
                    />
                }
                <div className={
                    (search.getActiveSource() === Constants.resultSource.lucidworks) ? (`FilterPanel SidebarSTablet ${sidebarOpen ? 'open' : sidebarStyles ? 'closing' : 'closed'}`)
                    : 'FilterPanel'
                    }>
                    <div className='TopPart'>
                        <div className='CloseButton'>
                            <IconButton
                                color='secondary'
                                onClick={handleSidebarClose}
                            ><ClearIcon sx={{fontSize: '1.8rem'}}/></IconButton>
                        </div>
                        { (props.searchParameters[Constants.parameter.didYouMean] !== Constants.didYouMean.noSuggestionsAvailable) && (
                            <ActiveFilters
                                cachedFilters={props.cachedFilters}
                                config={props.config}
                                isLoading={isLoading}
                                searchParameters={props.searchParameters}
                                searchResult={props.searchResult}
                                setCachedFilters={props.setCachedFilters}
                                setFilterResult={props.setFilterResult}
                                setSearchParameters={props.setSearchParameters}
                                setSearchResult={props.setSearchResult}
                                setpreviousFacets={props.setpreviousFacets}
                                sidebarOpen={sidebarOpen}
                                sidebarStyles={sidebarStyles}
                            />
                        )}
                    </div>
                    <div className='FiltersContainer'>
                        <LoadingTreeListFilter
                            config={props.config}
                            searchParameters={props.searchParameters}
                            previousFacets={props.previousFacets}
                        />
                        <LoadingCheckboxListFilter
                            config={props.config}
                            docFilterLoading={props.docFilterLoading}
                            searchParameters={props.searchParameters}
                        />
                        <LoadingXrefNotice
                            searchParameters={props.searchParameters}
                        />
                    </div>
                    <div className='BottomMenu'>
                        <div className='BottomMenuContentSTablet'>
                            <StyledButtonClose variant='contained' color='primary' onClick={handleSidebarClose}>
                                {`${search.getLabel(Labels.CheckboxListFilter.ShowResults())} (${props.statePrevTotalResult || 0})`}
                            </StyledButtonClose>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Sidebar.propTypes = {
    cachedFilters: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    setCachedFilters: PropTypes.func.isRequired,
    setFilterResult: PropTypes.func.isRequired,
    setSearchParameters: PropTypes.func.isRequired,
    setSearchResult: PropTypes.func.isRequired,
    setpreviousFacets: PropTypes.func.isRequired,
}

export default Sidebar;
