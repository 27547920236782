//
// React.
//
import PropTypes from 'prop-types'
//
// Material UI.
//
import { Box, Link, Typography } from '@mui/material'
//
// Search.
//
import Labels  from '../../../config/Labels'
import ResultItem from '../../../actions/ResultItem'
//
// CSS.
//
import './WikiWikiPageResultItem.css'

class WikiWikiPageResultItem extends ResultItem {

    render = () => {
        const resultItem = this.props.resultItem
        return (
            <div
                className='WikiWikiPageResultItem'
            >
                <Box sx={this.boxProperties}>{this.getLabel(Labels.WikiWikiPageResultItem.Chip)}</Box>
                <Typography variant='h5'>
                    <Link
                        href={ resultItem.uri }
                        target='_blank'
                        rel='noreferrer'
                        underline='none'
                        onClick={
                            (event) => {
                                this.sendResultClickedSignal(event)
                            }
                        }
                        dangerouslySetInnerHTML={{ __html: resultItem.display_title || resultItem.original_title }}
                    ></Link>
                </Typography>
                <Typography variant='body1'><span dangerouslySetInnerHTML={{ __html: resultItem.highlight }} /></Typography>
                <Typography variant='subtitle2'>{ resultItem.uri }</Typography>
            </div>
        )
    }
}

WikiWikiPageResultItem.propTypes = {
    position: PropTypes.number.isRequired,
    resultItem: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
}

export default WikiWikiPageResultItem