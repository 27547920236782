//
// React.
//
import PropTypes from 'prop-types'
//
// Search.
//
import Constants from '../config/Constants'
import Search from './Search'

class ResultItem extends Search {
    //
    // Defines the style and structure of bestBet in the results
    //
    firstResultBoxProperties = {
        border: 'solid 1px #dbdee2',
        backgroundColor: '#ffd200',
        borderRadius: '3px',
        color: '#03234b',
        display: 'inline-flex',
        fontSize: '13px',
        fontWeight: 'bold',
        pb: 0.25,
        pl: 1,
        pr: 1,
        pt: 0.25,
        mb: 1,
        mr: 1,
        textTransform: 'uppercase',
    }
    //
    // Standard style and structure box for the results
    //
    boxProperties = {
        border: 1,
        borderColor: 'grey.700',
        borderRadius: '4px',
        color: '#525a63',
        display: 'inline-flex',
        fontSize: '13px',
        pb: 0.25,
        pl: 1,
        pr: 1,
        pt: 0.25,
        mb: 1,
        textTransform: 'uppercase',
    }
    //
    // Sends the signal when the user clicks on a specific result
    //
    sendResultClickedSignal = (event) => {
        this.sendSignal(Constants.signal.resultClicked, this.props.searchParameters, [
            {
                name: 'href',
                values: [ event.target.href ],
            },
            {
                name: 'position',
                values: [ this.props.position ],
            }
        ])
    }
}

ResultItem.propTypes = {
    searchParameters: PropTypes.object.isRequired,
    position: PropTypes.number.isRequired,
}

export default ResultItem