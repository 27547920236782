//
// React.
//
import PropTypes from 'prop-types'
//
// Material UI.
//
import { Box, Link, Typography } from '@mui/material'
//
// Search.
//
import Labels  from '../../../config/Labels'
import ResultItem from '../../../actions/ResultItem'
//
// CSS.
//
import './WikiRepoFileResultItem.css'

class WikiRepoFileResultItem extends ResultItem {

    render = () => {
        const resultItem = this.props.resultItem
        return (
            <div
                className='WikiRepoFileResultItem'
            >
                <Box sx={this.boxProperties}>{this.getLabel(Labels.WikiRepoFileResultItem.Chip)}</Box>
                <Typography variant='h5'>
                    <Link
                        href={ resultItem.uri }
                        target='_blank'
                        rel='noreferrer'
                        underline='none'
                        onClick={
                            (event) => {
                                this.sendResultClickedSignal(event)
                            }
                        }
                    >
                        <img className='Image' src={ resultItem.image_uri } alt={ resultItem.filename }/>
                        { resultItem.filename }
                    </Link>
                </Typography>
                <Typography variant='subtitle2'>{ resultItem.uri }</Typography>
            </div>
        )
    }
}

WikiRepoFileResultItem.propTypes = {
    position: PropTypes.number.isRequired,
    resultItem: PropTypes.object.isRequired,
    searchParameters: PropTypes.object.isRequired,
}

export default WikiRepoFileResultItem