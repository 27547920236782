//
// React.
//
import PropTypes from 'prop-types'
//
// Search.
//
import Constants from '../config/Constants'
import Search from './Search'

class Filter extends Search {
    //
    // Construct a new instance.
    //
    constructor(props) {
        super(props)

        this.categoryLabels = this.props.config.filters[this.props.name]?.categoryLabels || {}
        this.separator = this.props.separator || Constants.categorySeparator
    }
    //
    // Clear dependent filters.
    //
    // clearDependentFilters = (filterName, searchParameters, updatedSelectedOptions, values) => {
    clearDependentFilters = (filterName, searchParameters, values) => {
            this.getDependentFilterNames(filterName).forEach(dependentFilterName => {
            const validValues = this.props.config.filters[dependentFilterName].display?.filter?.values || []
            //
            // If the dependent filter wouldn't be displayed, clear its values.
            //
            if (values.filter(value => validValues.includes(value)).length === 0) {
                searchParameters[dependentFilterName] = []
                // updatedSelectedOptions[dependentFilterName] = []
            }
        })
    }
    //
    // Retrieve all active filters based on the search parameters,
    // optionally filtered by result source.
    // Returns array of filter names, e.g. [ 'author', 'docType' ] .
    //
    getActiveFilters = (source) => {
        const filters = this.getConfiguredFilters()
        const searchParameters = this.props.searchParameters || []
        //
        // Find all search parameters representing a filter,
        // optionally filtered by result source.
        //
        var filterParameters = Object.keys(searchParameters).filter(parameter => Object.keys(filters).includes(parameter))
        if (source) {
            filterParameters = filterParameters.filter(filterName => (filters[filterName].display?.sources || []).includes(source))
        }
        //
        // Return all filter parameters with a value.
        //
        return filterParameters.filter(filterName => (searchParameters[filterName] && searchParameters[filterName].length > 0))
    }
    //
    // Get active options from current search request, e.g., [ 'pdf', 'web' ].
    //
    getActiveValues = (filterName) => {
        return (this.props.searchParameters && this.props.searchParameters[filterName]) || []
    }
    //
    // Get the filter configuration for the given name.
    //
    getConfiguredFilter = (filterName) => {
        return this.getConfiguredFilters()[filterName] || {}
    }
    //
    // Get the configured filters.
    //
    getConfiguredFilters = () => {
        return this.props.config?.filters || {}
    }
    //
    // Get the names of dependent filters.
    //
    getDependentFilterNames = (filterName) => {
        const filters = this.getConfiguredFilters()
        //
        // Search all configured filters for those having a dependency on the current filter.
        //
        var dependentFilters = []
        Object.keys(filters).forEach(candidateFilterName => {
            const filterDisplay = filters[candidateFilterName].display
            if (filterDisplay && (filterDisplay.filter?.fieldname === filterName)) {
                dependentFilters.push(candidateFilterName)
            }
        })
        return dependentFilters
    }
    //
    // Get the filter names for the given result source (all
    // filter names if this parameter is omitted).
    //
    getFilterNames = (source) => {
        const filters = this.getConfiguredFilters()
        const filterNames = Object.keys(filters)
        return (source) ? filterNames.filter(filterName => (filters[filterName].display?.sources || []).includes(source)) : filterNames
    }
    //
    // Get the number of documents represented by this filter.
    //
    getFilterResultCount = (filterName) => {
        return this.getValues(filterName).reduce((partialSum, category) => partialSum + category.count, 0)
    }
    //
    // Get the label for an option, including the document count, e.g., 'Word Document (132)' or 'img (23)'.
    //
    getOptionLabel = (option, values) => {
        const numberFormat = new Intl.NumberFormat(Constants.locale[this.getLanguage()])
        const category = (values || this.getValues(this.props.name)).find(category => (category.value === option))
        const label = this.getLabel(this.categoryLabels[option]) || option
        return `${label} (${numberFormat.format(category?.count || 0)})`
    }
    //
    // Get updated search parameters based on the application state (search parameters and
    // selected options) using the given current selected options. Dependent filters will be
    // cleared if necessary and an option removal is indicated in the call.
    //
    getUpdatedSearchParameters = (filterName, selectedOptions, optionRemoved) => {
        //
        // Create updated search parameters and selected options instances.
        //
        const updatedSearchParameters = {
            ...this.props.searchParameters,
            [filterName]: selectedOptions,
            [this.getPageParameter()]: 1,
        }
        //
        // If values have been removed, also clear dependent filter if necessary.
        //
        if (optionRemoved) {
            this.clearDependentFilters(filterName, updatedSearchParameters, selectedOptions)
        }
        //
        // Return the updated search parameters.
        //
        return updatedSearchParameters
    }
    /**
     * Get the filter's values (options) from the facet in the search result.
     *
     * @param {string} filterName
     * @param {string} source
     * @returns Array of values (options).
     */
    getValues = (filterName, source) => {
        const searchResult = this.props.searchResult || {}
        const facets = searchResult[source || this.getActiveSource()]?.result?.facets || []
        return (facets[filterName] || [])
    }
    //
    // Check if there are any active filters.
    //
    hasActiveFilters = () => {
        return (this.getActiveFilters().length > 0)
    }
    //
    // Decide whether to display a filter.
    //
    isDisplayEnabled = (filterName) => {
        //
        // Get filter configuration.
        //
        const filters = this.props.config?.filters || []
        const filter = filters[filterName] || {}
        //
        // Get filter display settings.
        //
        const displayFilter = filter.display?.filter
        const displayThreshold = filter.display?.threshold
        if (displayFilter) {
            //
            // Disable filter display if the target filter does not hold any valid values.
            //
            const activeValues = this.props.searchParameters[displayFilter.fieldname] || []
            const validValues = displayFilter.values || []
            return (activeValues.filter(value => validValues.includes(value)).length > 0)
        } else if (displayThreshold) {
            //
            // Disable filter display if the documents represented in the filter are less than
            // <displayThreshold> percent, but only if no active values are available.
            //
            const activeValues = this.props.searchParameters[filterName] || []
            const searchResult = this.props.searchResult || {}
            const result = searchResult[this.getActiveSource()]?.result || {}
            const totalResultCount = result.total || 0
            return (
                   (activeValues.length > 0)
                || (
                       (totalResultCount !== 0)
                    && (this.getFilterResultCount(filterName) / totalResultCount * 100 >= displayThreshold)
                )
            )
        }
        return true
    }
}

Search.propTypes = {
    config: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    searchParameters: PropTypes.object.isRequired,
    searchResult: PropTypes.object.isRequired,
    separator: PropTypes.string,
}

export default Filter