//
// React.
//
import PropTypes from 'prop-types'
import React from 'react'
//
// Search.
//
import Labels from '../../config/Labels'
import Search from '../../actions/Search'
import Util from '../../services/Util'
//
// CSS.
//
import './ErrorMessage.css'

class ErrorMessage extends Search {
    //
    // Render the error message.
    //
    render = () => {
        return (
            <div className='ErrorMessage'>
                <span className='Label'>{Util.getLabel(Labels.ErrorMessage.ErrorOccured, this.getLanguage())}:</span> {this.props.message}
            </div>
        )
    }
}

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired,
    searchParameters: PropTypes.object.isRequired,
}

export default ErrorMessage